import { React, useState } from "react";
import SuccessAlert from "../alerts/successalert";
import PropTypes from "prop-types";
import { updateEngagement } from "../../services/engagementServices";

export default function parameterscreen({ engagementData }) {
  const [isUpdated, setIsUpdated] = useState(false);
  const [formData, setFormData] = useState({
    description: (engagementData && engagementData.description) || "",
    state: (engagementData && engagementData.state) || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDismiss = () => {
    setIsUpdated(false);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await updateEngagement(engagementData.id, formData);
      setIsUpdated(true);
    } catch (e) {
      console.error(e);
    }
  };

  console.log(engagementData);

  return (
    <>
      {/* Alterte de mise à jour */}
      {isUpdated && (
        <div className="fixed top-10 right-20">
          <SuccessAlert
            onDismiss={handleDismiss}
            text="Vos modification ont été enregistrées avec succès."
          />
        </div>
      )}

      {/* Formulaire */}
      <form
        className="w-full mt-10 flex flex-col justify-center items-center"
        onSubmit={handleSubmit}
      >
        {/* Inputs */}
        <div className="w-2/3 m-4">
          {/* Description */}
          <div className="w-full">
            <label
              htmlFor=""
              className="block text-sm font-medium text-white display-linebreak"
            >
              Description de l&apos;engagement
            </label>
            <div className="mt-1">
              <textarea
                type="text"
                name="description"
                id="description"
                value={formData.description ? formData.description : ""}
                className="block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                placeholder="Changer la description de l'engagement"
                onChange={handleChange}
              />
            </div>
            <p
              className="mt-2 text-sm text-gray-500 display-linebreak"
              id="email-description"
            >
              Description
            </p>
          </div>
          {/* Selection de l'état de l'engagement */}
          <div className="w-full mt-12">
            <label
              htmlFor=""
              className="block text-sm font-medium text-white display-linebreak"
            >
              État de l&apos;engagement
            </label>
            <div className="mt-1">
              <select
                name="state"
                id="state"
                className="block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                value={formData.state ? formData.state : ""}
                onChange={handleChange}
              >
                <option value="">-</option>
                <option value="Brouillon">Brouillon</option>
                <option value="En cours">En cours</option>
                <option value="En attente">En attente</option>
                <option value="Signé">Signé</option>
                <option value="Suspendu">Suspendu</option>
                <option value="Archivé">Archivé</option>
              </select>
            </div>
            <p
              className="mt-2 text-sm text-gray-500 display-linebreak"
              id="email-description"
            >
              Description
            </p>
          </div>
        </div>
        {/* Bouton save */}
        <div className="fixed bottom-10 right-20">
          <button
            type="submit"
            className="block w-32 rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Sauvegarder
          </button>
        </div>
      </form>
    </>
  );
}

// Prop
parameterscreen.propTypes = {
  engagementData: PropTypes.object.isRequired,
};
