import React from "react";

import { FolderIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";

import { EngagementList, SectionHeader } from "../index";

const EngagementSection = ({ large, isHome, search }) => {
  let parametre = "lg:pr-96";
  if (large === "yes") {
    parametre = "";
  }

  let createVar = "engagement/create";
  let linkVar = "";

  if (isHome === "yes") {
    createVar = "";
    linkVar = "engagements";
  }

  return (
    <main className={parametre}>
      {/* HEADER */}
      <SectionHeader
        label={"Engagements"}
        link={"/engagements"}
        Icon={FolderIcon}
        Create={createVar}
        Link={linkVar}
      />

      {/* LIST */}
      <EngagementList search={search} />
    </main>
  );
};

EngagementSection.propTypes = {
  large: PropTypes.string,
  isHome: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
};

export default EngagementSection;
