import { db } from "../config/firebase";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { arrayRemove, arrayUnion } from "firebase/firestore";

const getAllUsers = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "users"));
    const usersList = [];
    for (const doc of querySnapshot.docs) {
      const userData = doc.data();
      const storage = getStorage();
      const profilePicRef = ref(storage, `profilePictures/${doc.id}.jpeg`);

      try {
        const profilePicUrl = await getDownloadURL(profilePicRef);
        userData.profilePicUrl = profilePicUrl;
      } catch (error) {
        console.error("Error fetching profile picture: ", error);
        userData.profilePicUrl = ""; // Handle missing profile picture case
      }

      usersList.push({ id: doc.id, ...userData });
    }
    return usersList;
  } catch (e) {
    console.error("Error getting documents: ", e);
    throw e; // rethrow the error for handling in the component
  }
};

const modifyUserById = async (userId, newData) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, newData);
    console.log(`User ${userId} has been updated.`);
  } catch (e) {
    console.error("Error updating user: ", e);
    throw e;
  }
};

// Fonction pour ajouter ou retirer un client des favoris de l'utilisateur
const updateFavorite = async (userId, clientId) => {
  try {
    const userRef = doc(db, "users", userId);

    // Récupérer l'utilisateur
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      throw new Error(`User with ID ${userId} does not exist.`);
    }

    const userData = userDoc.data();
    const favoriteClients = userData.favoriteClients || [];

    // Vérifier si le client est déjà dans la liste des favoris
    if (favoriteClients.includes(clientId)) {
      // Si oui, le retirer
      await updateDoc(userRef, {
        favoriteClients: arrayRemove(clientId),
      });
      console.log(`Client ${clientId} has been removed from favorites.`);
    } else {
      // Sinon, l'ajouter
      await updateDoc(userRef, {
        favoriteClients: arrayUnion(clientId),
      });
      console.log(`Client ${clientId} has been added to favorites.`);
    }
  } catch (e) {
    console.error("Error updating favorite clients: ", e);
    throw e;
  }
};

// Fonction pour ajouter ou retirer un engagement des favoris de l'utilisateur
const updateFavoriteEngagement = async (userId, engagementId) => {
  try {
    const userRef = doc(db, "users", userId);

    // Récupérer l'utilisateur
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      throw new Error(`User with ID ${userId} does not exist.`);
    }

    const userData = userDoc.data();
    const favoriteEngagements = userData.favoriteEngagements || [];

    // Vérifier si l'engagement est déjà dans la liste des favoris
    if (favoriteEngagements.includes(engagementId)) {
      // Si oui, le retirer
      await updateDoc(userRef, {
        favoriteEngagements: arrayRemove(engagementId),
      });
      console.log(
        `Engagement ${engagementId} has been removed from favorites.`,
      );
    } else {
      // Sinon, l'ajouter
      await updateDoc(userRef, {
        favoriteEngagements: arrayUnion(engagementId),
      });
      console.log(`Engagement ${engagementId} has been added to favorites.`);
    }
  } catch (e) {
    console.error("Error updating favorite engagements: ", e);
    throw e;
  }
};

export {
  getAllUsers,
  modifyUserById,
  updateFavorite,
  updateFavoriteEngagement,
};
