import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Listbox, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

import {
  getAllUsersFromTeamId,
  getTeamById,
  deleteUserFromTeam,
  addUserToTeam,
  deleteTeam,
} from "../services/teamServices";

import { getAllUsers } from "../services/userServices";
import { Treadmill } from "./loading/treadmill";
import SuccessAlert from "./alerts/successalert";

const TeamSection = () => {
  const { teamId } = useParams();

  const [users, setUsers] = useState([]); // State for users
  const [usersListbox, setUsersListbox] = useState([]); // State for users
  const [team, setTeam] = useState([]); // State for team
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPersons, setSelectedPersons] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [showModal, setShowModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const navigate = useNavigate();

  const customStyle = {
    height: "62px",
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersData = await getAllUsersFromTeamId(teamId);
        const usersListbox = await getAllUsers(teamId);
        setUsers(usersData);
        setUsersListbox(usersListbox);
        const teamData = await getTeamById(teamId);
        setTeam(teamData);
      } catch (error) {
        console.error("Error fetching users:", error);
        // Handle error
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Treadmill />
      </div>
    );
  }

  function isSelected(value) {
    return selectedPersons.find((el) => el === value.id) ? true : false;
  }

  function handleSelect(value) {
    if (!isSelected(value)) {
      setSelectedPersons([...selectedPersons, value.id]);
    } else {
      handleDeselect(value.id);
    }
    setIsOpen(true);
  }

  function handleDeselect(valueId) {
    const selectedPersonsUpdated = selectedPersons.filter(
      (id) => id !== valueId,
    );
    setSelectedPersons(selectedPersonsUpdated);
    setIsOpen(true);
  }

  const handleDeleteUser = async (userId) => {
    try {
      // Call service to delete user from the team
      await deleteUserFromTeam(teamId, userId);

      // Filter out the deleted user from the local state
      setUsers(users.filter((user) => user.id !== userId));
    } catch (error) {
      console.error("Error deleting user:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const getNameById = (id) => {
    const user = usersListbox.find((user) => user.id === id);
    return user ? user.name : null;
  };

  const getFirstnameById = (id) => {
    const user = usersListbox.find((user) => user.id === id);
    return user ? user.firstname : null;
  };

  function handleAddUser() {
    if (selectedPersons.length > 0) {
      selectedPersons.map((person) => {
        addUserToTeam(teamId, person);
      });
    }
  }

  const handleDeleteTeam = () => {
    //setShowModal(true);
    deleteTeam(teamId);
    setIsDeleted(true);
    navigate("/equipe");
  };

  // Fonction pour fermer l'alerte de succès
  const handleDismiss = () => {
    setIsDeleted(false);
  };

  return (
    <div className="w-3/5 mx-auto my-10">
      {isDeleted && (
        <SuccessAlert
          onDismiss={handleDismiss}
          text="Equipe supprimé avec succès !"
        />
      )}
      <h2 className="text-center text-white text-2xl font-semibold mt-3 mb-3">
        {team.teamName}
      </h2>
      <p className="text-center text-white text-sm font-normal leading-normal">
        {team.description}
      </p>
      <ul role="list" className="divide-y divide-gray-100 mt-5">
        {users.map((person) => (
          <li key={person.email} className="flex justify-between py-5">
            <div className="flex min-w-0 gap-x-4">
              <img
                className="h-12 w-12 flex-none rounded-full bg-gray-50"
                src={person.profilePicture}
                alt=""
              />
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-white">
                  {person.name + " " + person.firstname}
                </p>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                  {person.email}
                </p>
              </div>
            </div>
            <div className="flex gap-x-6">
              <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                <p className="text-sm leading-6 text-white">
                  {person.fonction}
                </p>
                {person.lastSeen ? (
                  <p className="mt-1 text-xs leading-5 text-gray-500">
                    Last seen{" "}
                    <time dateTime={person.lastSeenDateTime}>10 min ago</time>
                  </p>
                ) : (
                  <div className="mt-1 flex items-center gap-x-1.5">
                    <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                      <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                    </div>
                    <p className="text-xs leading-5 text-gray-500">Online</p>
                  </div>
                )}
              </div>
              <button
                onClick={() => handleDeleteUser(person.id)}
                className="bg-black/10 hover:bg-red-600 text-white font-bold py-2 px-4 border border-gray-400 rounded"
              >
                X
              </button>
            </div>
          </li>
        ))}
      </ul>
      <div className="flex flex-wrap w-full justify-between content-end mt-3">
        <div className="flex items-end justify-center" style={customStyle}>
          <button
            onClick={() => handleDeleteTeam()}
            className="flex-none bg-black/10 hover:bg-red-600 text-white font-bold py-2 px-4 border border-gray-400 rounded"
          >
            Supprimer
          </button>
        </div>
        <div className="flex justify-between content-end">
          <Listbox
            as="div"
            className="w-56"
            value={selectedPersons}
            onChange={(value) => handleSelect(value)}
            open={isOpen}
          >
            {() => (
              <>
                <Listbox.Label className="block text-sm font-semibold leading-6 text-white">
                  Membres de l&apos;équipe
                </Listbox.Label>
                <div className="relative">
                  <span className="inline-block w-full rounded-md shadow-sm">
                    <Listbox.Button
                      className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                      onClick={() => setIsOpen(!isOpen)}
                      open={isOpen}
                    >
                      <span className="block truncate">
                        {selectedPersons.length < 1
                          ? "Select persons"
                          : `Selected persons (${selectedPersons.length})`}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <svg
                          className="h-5 w-5 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </Listbox.Button>
                  </span>

                  <Transition
                    unmount={false}
                    show={isOpen}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="absolute mt-1 w-full rounded-md bg-white shadow-lg"
                  >
                    <Listbox.Options
                      static
                      className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                    >
                      {usersListbox.map((person) => {
                        const selected = isSelected(person);
                        return (
                          <Listbox.Option key={person.id} value={person}>
                            {({ active }) => (
                              <div
                                className={`${
                                  active
                                    ? "text-white bg-blue-600 flex items-center"
                                    : "text-gray-900 flex items-center"
                                } cursor-default select-none relative py-2 pl-8 pr-4 flex items-center`}
                              >
                                <img
                                  src={person.profilePicUrl}
                                  alt=""
                                  className="h-5 w-5 flex-shrink-0 rounded-full"
                                />
                                <span
                                  className={`${
                                    selected
                                      ? "font-semibold ml-3"
                                      : "font-normal ml-3"
                                  } block truncate`}
                                >
                                  {person.name + " " + person.firstname}
                                </span>
                                {selected && (
                                  <span
                                    className={`${
                                      active ? "text-white" : "text-blue-600"
                                    } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                  >
                                    <svg
                                      className="h-5 w-5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </div>
                            )}
                          </Listbox.Option>
                        );
                      })}
                    </Listbox.Options>
                  </Transition>
                  <div className="pt-1 text-sm text-gray-400">
                    {selectedPersons.length > 0 && (
                      <>
                        Selected persons:{" "}
                        {selectedPersons.map(
                          (personSelected) =>
                            getFirstnameById(personSelected) +
                            " " +
                            getNameById(personSelected) +
                            ", ",
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </Listbox>
          <div
            className="flex items-end justify-center ml-5"
            style={customStyle}
          >
            <button
              onClick={() => handleAddUser()}
              className="flex-none bg-black/10 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
