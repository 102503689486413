import { React, useState } from "react";
import PropTypes from "prop-types";
import createReport from "../../services/createReport";
import { saveAs } from "file-saver";
import { Packer } from "docx";
import { useParams } from "react-router-dom";

import SideBarItem from "./sidebaritem";
import { Treadmill } from "../loading/treadmill";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideBar({
  navigation,
  onNavigate,
  engagementId,
  engagementData,
}) {
  const queryParameters = new URLSearchParams(window.location.search);
  const currentScreenId = queryParameters.get("screen");
  const [isCreatingReport, setIsCreatingReport] = useState(false);

  const testCreateReport = async (engagementId, engagementData) => {
    setIsCreatingReport(true);
    const doc = await createReport(engagementId, engagementData);

    if (!doc) {
      console.error("Error creating document");
      return;
    }

    Packer.toBlob(doc).then((blob) => {
      console.log(blob);
      saveAs(blob, "Rapport.docx");
      console.log("Document created successfully");
    });

    setIsCreatingReport(false);
  };

  // Set the current item to active when the screen id matches the item id
  function markCurrentItems(navigation, currentScreenId) {
    navigation.forEach((item) => {
      if (item.id === currentScreenId) {
        item.current = true;
      } else {
        item.current = false;
      }
      if (item.children) {
        markCurrentItems(item.children, currentScreenId); // Appel récursif pour chaque élément enfant
      }
    });
  }

  markCurrentItems(navigation, currentScreenId);

  return (
    <div className="flex w-1/5 flex-col flex-grow border-r border-gray-700 pb-4 bg-gray-900 overflow-y-auto">
      <div className="mt-5 flex-grow flex flex-col">
        <nav className="flex-1 px-2 space-y-1" aria-label="Sidebar">
          {navigation.map((item) => (
            <SideBarItem key={item.id} item={item} onNavigate={onNavigate} />
          ))}
          {/* Create report button */}
          {isCreatingReport == false ? (
            <button
              onClick={() => testCreateReport(engagementId, engagementData)}
              className={
                "text-gray-400 hover:text-white hover:bg-gray-800 group flex justify-start text-left gap-x-3 w-full rounded-md p-2 text-sm leading-6 font-semibold"
              }
            >
              Create report
            </button>
          ) : (
            // Loading spinner if report is being created
            <div className="flex justify-center group flex gap-x-3 w-full rounded-md p-4 text-sm text-left leading-6 font-semibold bg-gray-800">
              <Treadmill color="white" size="30" speed="0.5" />
            </div>
          )}
        </nav>
      </div>
    </div>
  );
}

SideBar.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          href: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  onNavigate: PropTypes.func.isRequired,
  engagementId: PropTypes.string.isRequired,
  engagementData: PropTypes.object.isRequired,
};
