import React, { useState } from "react";

import { Navbar, SearchBar, ActivitySection } from "../components";
import EngagementSection from "../components/engagement/engagementsection";

const Accueil = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [search, setSearch] = useState(""); // état pour stocker la recherche

  return (
    <div className="h-full">
      {/* LEFT PANEL */}
      <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* MAIN PANEL */}
      <div className="xl:pl-72 h-full">
        <SearchBar setSidebarOpen={setSidebarOpen} setSearch={setSearch} />
        <EngagementSection isHome="yes" large={"yes"} search={search} />
        {/* <ActivitySection /> */}
      </div>
    </div>
  );
};

export default Accueil;
