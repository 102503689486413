import React, { useState } from "react";

import Engagement from "../components/engagement/engagement";
import ProfileSection from "../components/profilesection";
import Topnavbar from "../components/navbar/topnavbar";
import SideBar from "../components/navbar/sidebar";

const Engagements = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="h-full">
      {/* TOP PANEL */}
      <Topnavbar />

      {/* MAIN PANEL */}
      <div className="h-full">
        <Engagement large={"yes"} />
      </div>
    </div>
  );
};

export default Engagements;
