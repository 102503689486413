import { db } from "../config/firebase";
import {
  doc,
  getDoc,
  addDoc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  deleteDoc,
  limit,
} from "firebase/firestore";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  uploadString,
  listAll,
} from "firebase/storage";

import { useAuth } from "../contexts/AuthContext";

const storage = getStorage();

// Main screens
const screens = {
  screens: [
    {
      id: "1",
      name: "Généralités",
      subtitle: "Informations générales",
      description: "Informations générales de l'engagement",
      hasChildren: true,
      children: [
        {
          id: "11",
          name: "Paramètres",
          subtitle: "Paramètres de l'engagement",
          description: "Description de l'engagement",
          hasChildren: false,
          inputs: [
            {
              id: "111",
              label: "Date de début de l'engagement",
              type: "date",
              name: "date",
              input_id: "date",
              placeholder: "20XX-XX-XX",
              description: "Entrez la date de début de l'engagement",
              class: "w-full",
            },
            {
              id: "112",
              label: "Date de fin de l'engagement",
              type: "date",
              name: "date",
              input_id: "date",
              placeholder: "20XX-XX-XX",
              description: "Entrez la date de fin de l'engagement",
              class: "w-full",
            },
            {
              id: "113",
              label: "Aimez vous l'audit ?",
              type: "text",
              name: "text",
              input_id: "text",
              placeholder: "Alors vous aimez ça ?",
              description: "Répondez honpetement sinon je m'énerve",
              class: "w-full",
            },
            {
              id: "114",
              label: "Dites nous pourquoi vous aimez l'audit !",
              type: "textarea",
              name: "textarea",
              input_id: "textarea",
              placeholder: "En quelque lignes",
              description: "Vous avez 4h",
              class: "w-full",
            },
            {
              id: "115",
              label: "Pourriez-vous mourrir pour l'audit ?",
              type: "radio",
              name: "radio",
              input_id: "radio",
              placeholder: "Oui",
              description: "C'est pas une question piège",
              class: "w-full",
              options: [
                {
                  label: "Oui",
                  description: "Immolez-moi tout de suite",
                  value: "oui",
                  id: "1151",
                },
                {
                  label: "Non",
                  description: "Je suis un lâche",
                  value: "non",
                  id: "1152",
                },
              ],
            },
            {
              id: "116",
              label: "Quelle est votre couleur préférée ?",
              type: "checkbox",
              name: "checkbox",
              input_id: "checkbox",
              description: "C'est pour une étude",
              class: "w-full",
              options: [
                {
                  label: "Bleu",
                  description: "C'est la couleur du ciel",
                  value: "bleu",
                  id: "1161",
                },
                {
                  label: "Rouge",
                  description: "C'est la couleur du sang",
                  value: "rouge",
                  id: "1162",
                },
                {
                  label: "Vert",
                  description: "C'est la couleur de l'herbe",
                  value: "vert",
                  id: "1163",
                },
              ],
            },
            {
              id: "117",
              label: "Selectionnez les couches pertinentes",
              type: "select",
              name: "select",
              input_id: "select",
              description: "C'est pour une étude",
              class: "w-full",
              options: [
                {
                  label: "Couche 1",
                  description: "C'est la première couche",
                  value: "couche1",
                  id: "1171",
                },
                {
                  label: "Couche 2",
                  description: "C'est la deuxième couche",
                  value: "couche2",
                  id: "1172",
                },
                {
                  label: "Couche 3",
                  description: "C'est la troisième couche",
                  value: "couche3",
                  id: "1173",
                },
              ],
            },
          ],
        },
        {
          id: "12",
          name: "Interlocuteurs",
          subtitle: "Interlocuteurs de l'engagement",
          description: "Description de l'engagement",
          hasChildren: false,
        },
        {
          id: "13",
          name: "Suivi",
          subtitle: "Suivi de l'engagement",
          description:
            "Cet écran permet de suivre l'avancement de l'engagement",
          hasChildren: false,
        },
      ],
    },
    {
      id: "2",
      name: "Périmètre applicatif",
      subtitle: "Périmètre applicatif de l'engagement",
      description: "Description de l'engagement",
      hasChildren: true,
      children: [
        {
          id: "21",
          name: "Applications auditées",
          subtitle: "Applications auditées de l'engagement",
          description: "Description de l'engagement",
          hasChildren: false,
        },
      ],
    },
    {
      id: "3",
      name: "Environnement IT",
      subtitle: "Environnement IT de l'engagement",
      description: "Description de l'engagement",
      hasChildren: true,
      children: [
        {
          id: "31",
          name: "Questionnaire de compatibilité",
          subtitle: "Questionnaire de compatibilité de l'engagement",
          description: "Description de l'engagement",
          hasChildren: false,
        },
        {
          id: "32",
          name: "Complément d'information",
          subtitle: "Complément d'information de l'engagement",
          description: "Description de l'engagement",
          hasChildren: false,
        },
        {
          id: "33",
          name: "Cartographie applicative",
          subtitle: "Cartographie applicative de l'engagement",
          description: "Description de l'engagement",
          hasChildren: false,
        },
      ],
    },
  ],
};

// App screens
function appScreens(app_name, app_id = "10") {
  return [
    {
      id: app_id + ".1",
      name: app_name,
      subtitle: app_name + " de l'engagement",
      description: "Description de l'engagement",
      hasChildren: true,
      children: [
        {
          id: app_id + "11",
          name: "Access to program and data",
          subtitle: "Access to program and data de l'engagement",
          description: "Description de l'engagement",
          hasChildren: true,
          children: [
            {
              id: app_id + "12",
              name: "APD1 - Création Accès",
              subtitle: "APD1 - Accès aux Programmes et aux Données n°1",
              description: `
### Description du point de contrôle :
*Validation par une personne compétente de toutes les demandes de création et modification d’accès à l’application pour aligner les droits d’accès avec les fonctions opérationnelles.*

### Risques du point de contrôle :
*Risque de gestion inadéquate des accès au système d’information, possibilité de détournement de comptes en cas d’actes malveillants, et réalisation d’opérations non autorisées dépassant les droits de l’utilisateur.*

### Conception du contrôle :
*Établissement d’une procédure formelle pour la gestion des accès, incluant les étapes de création et modification des accès, assurant l’alignement des droits avec les responsabilités.*`,
              hasChildren: false,
              inputs: [
                {
                  id: "APD1 Procédure formalisée",
                  label: `
### Existe-il une procédure formalisée (écrite) de création de comptes sur l’application ?`,
                  type: "radio",
                  name: "radio_1",
                  input_id: "radio_1",
                  description: "",
                  class: "w-full",
                  options: [
                    {
                      label: "OUI",
                      description:
                        "Il existe une procédure formalisée de création des comptes",
                      value: "OUI",
                      id: 61,
                    },
                    {
                      label: "NON",
                      description:
                        "Il n'existe pas de procédure formalisée de création des comptes",
                      value: "NON",
                      id: 62,
                    },
                  ],
                },
                {
                  id: "APD1 Détails du processus de création des accès - Description",
                  label: `
### Décrire la procédure de création de comptes

**Aide à la description :**

* _Comment sont faites ces demandes de création de comptes : par quel outil ou quel moyen de communication ?_
* _Qui sont les personnes habilitées pour demander de création de comptes ?_
* _Qui sont les personnes responsables pour valider la demande de création ?_ `,

                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder: "Processus de création des accès.",
                  description:
                    "Attacher les pièces jointes : fichiers bruts et résultats des contrôles :",
                  class: "w-full",
                },
                {
                  id: "APD1 Détails du processus de création des accès - Efficacité opérationnelle",
                  label: `
### Analyse des créations et modification des accès :

* Obtenir la liste des utilisateurs de l’application
* Analyser la liste des utilisateurs (_Filtrer les utilisateurs créés sur la période, sélectionner un échantillon conforme à la table d'échantillonnage et s’assurer que ces créations sont légitimes_)

#### Table d'échantillonnage :
| Population  | Echantillon |
| ----------- | ----------- |
|   < 100     |     15%     |
|   < 400     |     10%     |
|   > 400     |      7%     |
`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Détails des différentes étapes de l'analyse et des exceptions, si nécessaire.",
                  description:
                    "Attacher les pièces jointes : fichiers bruts et résultats des contrôles",
                  class: "w-full",
                },

                {
                  id: "APD1 Contrôle effectif",
                  suivi: true,
                  label: "Le contrôle est-il effectif ?",
                  type: "radio",
                  name: "radio_2",
                  input_id: "radio_2",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "OUI",
                      description: "Le contrôle est effectif",
                      value: "OUI",
                      id: 63,
                    },
                    {
                      label: "NON",
                      description: "Le contrôle n'est pas effectif",
                      value: "NON",
                      id: 64,
                    },
                  ],
                },
                {
                  id: "APD1 niveau de criticité",
                  suivi: true,
                  label: "Quel est le niveau de criticité de ce contrôle ?",
                  type: "radio",
                  name: "radio_3",
                  input_id: "radio_3",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "Faible",
                      description: "Le niveau de criticité est faible",
                      value: "Faible",
                      id: 65,
                    },
                    {
                      label: "Modéré",
                      description: "Le niveau de criticité est modéré",
                      value: "Modéré",
                      id: 66,
                    },
                    {
                      label: "Elevé",
                      description: "Le niveau de criticité est élevé",
                      value: "Elevé",
                      id: 67,
                    },
                    {
                      label: "Non applicable",
                      description: "Le niveau de criticité est non applicable",
                      value: "Non applicable",
                      id: 70,
                    },
                  ],
                },
                {
                  id: "APD1 Détails du processus de création des accès - Analyse d'impact",
                  label: `
### Analyse d'impact pour ce point de contrôle :

_Décrire l’impact de cette criticité sur les états financiers et la façon dont le risque est mitigé_`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Décrire l'impact de ce point de contrôle sur les états financiers",
                  description:
                    "Si le contrôle n'est pas effectif, décrire l'impact de ce point de contrôle sur les états financiers",
                  class: "w-full",
                },
                {
                  id: "APD1 Détail du processus de création des accès - Recommandations",
                  label: `
### Recommandations pour ce point de contrôle

*Exemple :*
* _Ajouter une recommandation sur la création d’un processus fiable de création des accès_,
* _Ajouter une recommandation sur la formalisation d’une procédure_
`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder: "",
                  description: " ",
                  class: "w-full",
                },
              ],
            },
            {
              id: app_id + "13",
              name: "APD2 - Désactivation Accès",
              subtitle: "APD2 - Accès aux Programmes et aux Données n°2",
              description: `
### Description du point de contrôle :
_Les droits d'accès des utilisateurs ne sont pas désactivés dans un délai raisonnable._
### Risques du point de contrôle:
* _Risque de non-maîtrise des accès internes et externes au SI._
* _Risque de détournement de comptes utilisateurs et d'actifs en cas d'actes malveillants ou d'usurpation d'identité._
* _Enregistrement d’opérations non autorisées, au-delà des prérogatives de l’utilisateur._
### Conception du point de contrôle :
_Obtenir la procédure de gestion des accès du personnel et des tiers. La procédure devra couvrir les processus de désactivation des accès._`,
              hasChildren: false,
              inputs: [
                {
                  id: "APD2 Procédure formalisée",
                  label:
                    "Existe-il une procédure formalisée (écrite) de désactivation de comptes sur l’application ?",
                  type: "radio",
                  name: "radio_4",
                  input_id: "radio_4",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "OUI",
                      description:
                        "Il existe une procédure formalisée de désactivation des comptes",
                      value: "OUI",
                      id: 68,
                    },
                    {
                      label: "NON",
                      description:
                        "Il n'existe pas de procédure formalisée de désactivation des comptes",
                      value: "NON",
                      id: 69,
                    },
                  ],
                },
                {
                  id: "APD2 Détails du processus de désactivation des accès - Description",
                  label: `
### Description du processus de désactivation des accès

Aide à la description :
* _Comment sont faites ces demandes de désactivation de comptes : par quel outil ou quel moyen de communication ?
* _Les dasactivations de comptes sont elles automatique en cas de sortie de l'entreprise ?_`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder: "Processus de désactivation des accès",
                  description:
                    "Description du processus de désactivation des accès : ",
                  class: "w-full",
                },
                {
                  id: "APD2 Détails du processus de désactivation des accès - Efficacité opérationnelle",
                  label: `
### Analyse des désactivation d'accès aux systèmes :
* Obtenir la liste des utilisateurs de l’application ainsi que la liste des départ RH sur l'exercice.
* Rapprocher la liste des utilisateurs actifs et des départs RH.`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Détails des différentes étapes de l'analyse et des exceptions, si nécessaire :",
                  description:
                    "Attacher les pièces jointes : fichiers bruts et résultats des contrôles : ",
                  class: "w-full",
                },
                {
                  id: "APD2 Contrôle effectif",
                  suivi: true,
                  label: "Le contrôle est-il effectif ?",
                  type: "radio",
                  name: "radio_5",
                  input_id: "radio_5",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "OUI",
                      description: "Le contrôle est effectif",
                      value: "OUI",
                      id: 61,
                    },
                    {
                      label: "NON",
                      description: "Le contrôle n'est pas effectif",
                      value: "NON",
                      id: 62,
                    },
                  ],
                },
                {
                  id: "APD2 niveau de criticité",
                  suivi: true,
                  label: "Quel est le niveau de criticité de ce contrôle ?",
                  type: "radio",
                  name: "radio_6",
                  input_id: "radio_6",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "Faible",
                      description: "Le niveau de criticité est faible",
                      value: "Faible",
                      id: 61,
                    },
                    {
                      label: "Modéré",
                      description: "Le niveau de criticité est modéré",
                      value: "Modéré",
                      id: 62,
                    },
                    {
                      label: "Elevé",
                      description: "Le niveau de criticité est élevé",
                      value: "Elevé",
                      id: 63,
                    },
                    {
                      label: "Non applicable",
                      description:
                        "Aucune criticité n'est applicable sur ce point de contrôle",
                      value: "Non applicable",
                      id: 64,
                    },
                  ],
                },
                {
                  id: "APD2 Détails du processus de désactivation des accès - Analyse d'impact",
                  label: `
### Analyse d'impact pour ce point de contrôle
Décrire l’impact de cette criticité sur les états financiers et la façon dont le risque est mitigé `,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Si le contrôle n'est pas effectif, décrire l'impact de ce point de contrôle sur les états financiers",
                  description: " ",
                  class: "w-full",
                },
                {
                  id: "APD2 Détail du processus de désactivation des accès - Recommandations",
                  label: `
### Recommandations pour ce point de contrôle
_Exemple :
* _Ajouter une recommandation sur la création d’un processus fiable de désactivation des accès
* _Ajouter une recommandation sur la formalisation d’une procédure `,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder: "",
                  description: " ",
                  class: "w-full",
                },
              ],
            },
            {
              id: app_id + "14",
              name: "APD3 - Droits Etendus",
              subtitle: "APD3 - Accès aux Programmes et aux Données n°3",
              description: `
### Description du point de contrôle :
_Les comptes ayant des droits d’accès étendus comme les administrateurs ou les super utilisateurs ne sont pas suffisamment restreints ou supervisés._
### Risques du point de contrôle :
* _Risque de non-maîtrise des accès internes et externes au SI._
* _Risque de détournement de comptes utilisateurs et d'actifs en cas d'actes malveillants ou d'usurpation d'identité._
* _Enregistrement d’opérations non autorisées, au-delà des prérogatives de l’utilisateur._
### Conception du point de contrôle :
_Obtenir la procédure de gestion des accès ayant des droits étendus (administrateurs ou super-utilisateurs)._
_La procédure devra couvrir les personnes autorisées à posséder ces droits et la revue périodique de ces droits._`,
              hasChildren: false,
              inputs: [
                {
                  id: "APD3 Procédure formalisée",
                  label:
                    "Existe-il une procédure formalisée (écrite) de gestion des comptes à droits étendus sur l’application ?",
                  type: "radio",
                  name: "radio_7",
                  input_id: "radio_7",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "OUI",
                      description:
                        "Il existe une procédure formalisée de gestion des comptes à droits étendus",
                      value: "OUI",
                      id: 61,
                    },
                    {
                      label: "NON",
                      description:
                        "Il n'existe pas de procédure formalisée de gestion des comptes à droits étendus",
                      value: "NON",
                      id: 62,
                    },
                  ],
                },
                {
                  id: "APD3 Détails du processus de gestion des accès à droits étendus - Description",
                  label: `
### Description du processus de gestion des accès à droits étendus
_Aide à la description :_
* _Existe-t-il des contrôles sur les comptes à droits étendus (ex. processus de validation de ces comptes, documentation, revue, surveillance, politique de nommage, etc.)_
* _Les comptes à droits étendus sont-ils identifiables et leur accès est-il restreint ?_
* _Des contrôles sont-ils en place afin de prévenir le risque d'accès non autorisé au moyen des comptes à droits étendus ?_`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Processus de gestion des accès à droits étendus",
                  description:
                    "Description du processus de gestion des accès à droits étendus: ",
                  class: "w-full",
                },
                {
                  id: "APD3 Détails du processus de gestion des accès étendus - Efficacité opérationnelle",
                  label:
                    "Analyse des comptes à droits étendus (administrateurs, super-utilisateurs, etc.). : \n ●	Obtenir la liste des utilisateurs de l’application. \n ●	●	Analyser la liste des utilisateurs (i) | \n (i) Filtrer sur le profil des droits de l’utilisateur, s’assurer que la liste des comptes à droits étendus est suffisamment restreinte, vérifier la légitimité des comptes à droits étendus. \n",
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Détails des différentes étapes de l'analyse et des exceptions, si nécessaire :",
                  description:
                    "Attacher les pièces jointes : fichiers bruts et résultats des contrôles : ",
                  class: "w-full",
                },
                {
                  id: "APD3 Contrôle effectif",
                  suivi: true,
                  label: "Le contrôle est-il effectif ?",
                  type: "radio",
                  name: "radio_8",
                  input_id: "radio_8",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "OUI",
                      description: "Le contrôle est effectif",
                      value: "OUI",
                      id: 61,
                    },
                    {
                      label: "NON",
                      description: "Le contrôle n'est pas effectif",
                      value: "NON",
                      id: 62,
                    },
                  ],
                },
                {
                  id: "APD3 niveau de criticité",
                  suivi: true,
                  label: "Quel est le niveau de criticité de ce contrôle ?",
                  type: "radio",
                  name: "radio_9",
                  input_id: "radio_9",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "Faible",
                      description: "Le niveau de criticité est faible",
                      value: "Faible",
                      id: 61,
                    },
                    {
                      label: "Modéré",
                      description: "Le niveau de criticité est modéré",
                      value: "Modéré",
                      id: 62,
                    },
                    {
                      label: "Elevé",
                      description: "Le niveau de criticité est élevé",
                      value: "Elevé",
                      id: 63,
                    },
                    {
                      label: "Non Applicable",
                      description:
                        "Aucun niveau de criticité n'est applicable sur ce point de contrôle",
                      value: "Non Applicable",
                      id: 64,
                    },
                  ],
                },
                {
                  id: "APD3 Détails du processus de gestion des comptes à droits étendus - Analyse d'impact",
                  label: `
### Analyse d'impact pour ce point de contrôle
Décrire l’impact de cette criticité sur les états financiers et la façon dont le risque est mitigé`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Si le contrôle n'est pas effectif, décrire l'impact de ce point de contrôle sur les états financiers",
                  description: " ",
                  class: "w-full",
                },
              ],
            },
            {
              id: app_id + "15",
              name: "APD4 - Comptes Génériques",
              subtitle: "APD4 - Accès aux Programmes et aux Données n°4",
              description: `
### Description du point de contrôle :
_Les comptes génériques ne sont pas identifiés et leur utilisation est maîtrisée par le management. (Un compte génériques un compte non nominatif)_
### Risques du point de contrôle :
* _La traçabilité des opérations effectuées n’est pas assurée via l’utilisation de comptes génériques.
* _Risque de non-maîtrise des accès internes et externes au SI.
### Conception du point de contrôle :
* _Obtenir la procédure de gestion des comptes génériques à l’application.X. La procédure devra couvrir les personnes autorisées à posséder ces droits et la revue périodique de ces droits. `,
              hasChildren: false,
              inputs: [
                {
                  id: "APD4 Procédure formalisée",
                  label:
                    "Existe-il une procédure formalisée (écrite) de gestion des comptes génériques ?",
                  type: "radio",
                  name: "radio_10",
                  input_id: "radio_10",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "OUI",
                      description:
                        "Il existe une procédure formalisée de gestion des comptes génériques",
                      value: "OUI",
                      id: 61,
                    },
                    {
                      label: "NON",
                      description:
                        "Il n'existe pas de procédure formalisée de gestion des comptes génériques",
                      value: "NON",
                      id: 62,
                    },
                  ],
                },

                {
                  id: "APD4 Détails du processus de gestion des comptes génériques - Description",
                  label: `
### Description du processus de gestion des comptes génériques
_D’après la procédure ou par entretien :_
* _Existe-t-il des contrôles sur les comptes génériques (ex. processus de validation de ces comptes, documentation, revue, surveillance, politique de nommage, etc.) ?_
* _Existe-t-il une politique de nommage des comptes ?_
* _Les comptes génériques sont-ils identifiables et leur accès est-il restreint ?_
* _Des contrôles sont-ils en place afin de prévenir le risque d'accès non autorisé au moyen des comptes à droits étendus ?_
* _Existe-t-il des contrôles sur les comptes à droits étendus (ex. processus de validation de ces comptes, documentation, revue, surveillance, politique de nommage, etc.)_
* _Les comptes à droits étendus sont-ils identifiables et leur accès est-il restreint ?_
* _Des contrôles sont-ils en place afin de prévenir le risque d'accès non autorisé au moyen des comptes à droits étendus ?_`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Décrire le processus de gestion des comptes génériques",
                  description:
                    "Attacher les pièces jointes : fichiers bruts et résultats des contrôles :",
                  class: "w-full",
                },
                {
                  id: "APD4 Détails du processus de gestion des comptes génériques - Efficacité opérationnelle",
                  label: `
### Analyse des comptes génériques :
* _Obtenir la liste des utilisateurs de l’application._
* _Analyser la liste des utilisateurs: Filtrer sur le profil des droits de l’utilisateur, s’assurer que la liste des comptes génériques est suffisamment restreinte, vérifier qui a accès à ces comptes, vérifier la légitimité de ces comptes._`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Détails des différentes étapes de l'analyse et des exceptions, si nécessaire :",
                  description:
                    "Attacher les pièces jointes : fichiers bruts et résultats des contrôles :",
                  class: "w-full",
                },
                {
                  id: "APD4 Contrôle effectif",
                  suivi: true,
                  label: "Le contrôle est-il effectif ?",
                  type: "radio",
                  name: "radio_11",
                  input_id: "radio_11",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "OUI",
                      description: "Le contrôle est effectif",
                      value: "OUI",
                      id: 61,
                    },
                    {
                      label: "NON",
                      description: "Le contrôle n'est pas effectif",
                      value: "NON",
                      id: 62,
                    },
                  ],
                },
                {
                  id: "APD4 niveau de criticité",
                  suivi: true,
                  label: "Quel est le niveau de criticité de ce contrôle ?",
                  type: "radio",
                  name: "radio_12",
                  input_id: "radio_12",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "Faible",
                      description: "Le niveau de criticité est faible",
                      value: "Faible",
                      id: 61,
                    },
                    {
                      label: "Modéré",
                      description: "Le niveau de criticité est modéré",
                      value: "Modéré",
                      id: 62,
                    },
                    {
                      label: "Elevé",
                      description: "Le niveau de criticité est élevé",
                      value: "Elevé",
                      id: 63,
                    },
                    {
                      label: "Non Applicable",
                      description:
                        "Aucun niveau de criticité n'est applicable sur ce point de contrôle",
                      value: "Non Applicable",
                      id: 64,
                    },
                  ],
                },

                {
                  id: "APD4 Détails du processus de gestion des comptes génériques - Analyse d'impact",
                  label: `
### Analyse d'impact pour ce point de contrôle :
Décrire l’impact de cette criticité sur les états financiers et la façon dont le risque est mitigé`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Si le contrôle n'est pas effectif, décrire l'impact de ce point de contrôle sur les états financiers",
                  description: " ",
                  class: "w-full",
                },
              ],
            },
            {
              id: app_id + "16",
              name: "APD5 - Revue des Comptes",
              subtitle: "APD5 - Accès aux Programmes et aux Données n°5",
              description: `
### Description du point de contrôle :
_Le management revoit périodiquement les droits d'accès aux applications pour s'assurer qu'ils correspondent aux responsabilités opérationnelles. Les anomalies identifiées, par le management lors de sa revue, sont investiguées et résolues en temps opportuns._
### Risques du point de contrôle :
* _Risque de non-maîtrise des accès internes et externes au SI.
* _Enregistrement d’opérations non autorisées, au-delà des prérogatives de l’utilisateur Accès non autorisé à des programmes critiques permettant de réaliser des actes malveillants/frauduleux.
### Conception du point de contrôle :
_Obtenir la procédure de revue des comptes de l’application. La procédure devra couvrir les étapes de réalisation d’une revue. Une revue doit être fait au moins une fois pendant la période fiscale et devra documenter les actions à effectuer sur les comptes identifiés (suppression, revue des droits, etc.)._`,
              hasChildren: false,
              inputs: [
                {
                  id: "APD5 Procédure formalisée",
                  label:
                    "Existe-il une procédure formalisée (écrite) de revue des comptes ?",
                  type: "radio",
                  name: "radio_12",
                  input_id: "radio_12",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "OUI",
                      description:
                        "Il existe une procédure formalisée de revue des comptes ",
                      value: "OUI",
                      id: 61,
                    },
                    {
                      label: "NON",
                      description:
                        "Il n'existe pas de procédure formalisée de revue des comptes ",
                      value: "NON",
                      id: 62,
                    },
                  ],
                },
                {
                  id: "APD5 Détails du processus de revue des comptes - Description",
                  label: `
### Description du processus de revue des comptes :
_D’après la procédure ou par entretien :_
* _Une revue des comptes a t-elle été effectuée au cours de la période fiscale ?_
* _A quelle fréquence la revue des comptes est-elle réalisée ?_
* _Comment est documentée la revue ?_
* _Des anomalies ont-elle été détecté au cours de la revue ? Si oui, quelles actiones ont été prises pour résoudre ces anomalies ?_`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder: "Processus de revue des comptes",
                  description:
                    "Attacher les pièces jointes : fichiers bruts et résultats des contrôles :",
                  class: "w-full",
                },
                {
                  id: "APD5 Détails du processus de revue des comptes - Efficacité opérationnelle",
                  label: `
### Analyse de la revue des comptes :
* _Obtenir la/les revue de comptes effectuée._
* _Analyser la/les revue de comptes_`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Détails des différentes étapes de l'analyse et des exceptions, si nécessaire :",
                  description:
                    "Attacher les pièces jointes : fichiers bruts et résultats des contrôles : ",
                  class: "w-full",
                },
                {
                  id: "APD5 Contrôle effectif",
                  suivi: true,
                  label: "Le contrôle est-il effectif ?",
                  type: "radio",
                  name: "radio_13",
                  input_id: "radio_13",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "OUI",
                      description: "Le contrôle est effectif",
                      value: "OUI",
                      id: 61,
                    },
                    {
                      label: "NON",
                      description: "Le contrôle n'est pas effectif",
                      value: "NON",
                      id: 62,
                    },
                  ],
                },
                {
                  id: "APD5 niveau de criticité",
                  suivi: true,
                  label: `
### Quel est le niveau de criticité de ce contrôle ?`,
                  type: "radio",
                  name: "radio_14",
                  input_id: "radio_14",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "Faible",
                      description: "Le niveau de criticité est faible",
                      value: "Faible",
                      id: 61,
                    },
                    {
                      label: "Modéré",
                      description: "Le niveau de criticité est modéré",
                      value: "Modéré",
                      id: 62,
                    },
                    {
                      label: "Elevé",
                      description: "Le niveau de criticité est élevé",
                      value: "Elevé",
                      id: 63,
                    },
                    {
                      label: "Non Applicable",
                      description:
                        "Aucun niveau de criticité n'est applicable sur ce point de contrôle",
                      value: "Non Applicable",
                      id: 64,
                    },
                  ],
                },
                {
                  id: "APD5 Détails du processus de revue des comptes - Analyse d'impact",
                  label: `
### Analyse d'impact pour ce point de contrôle :
_Décrire l’impact de cette criticité sur les états financiers et la façon dont le risque est mitigé_`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Si le contrôle n'est pas effectif, décrire l'impact de ce point de contrôle sur les états financiers",
                  description: " ",
                  class: "w-full",
                },
              ],
            },
            {
              id: app_id + "17",
              name: "APD6 - Mots de Passe",
              subtitle: "APD6 - Accès aux Programmes et aux Données n°6",
              description: `
### Description du point de contrôle :
_Les accès aux applications / bases de données / systèmes d'exploitation sont protégés par un mot de passe robuste (ex. longueur minimale, critères de complexité, durée de validité, nombre de précédents mots de passe ne pouvant être réutilisés, nombre de tentatives infructueuses avant blocage, etc.)._
### Risques du point de contrôle :
_Risque de non-maitrise des accès internes et externes au SI. Risque de détournement de comptes utilisateurs et d'actifs en cas d'actes malveillants ou d'usurpation d'identité._
### Conception du point de contrôle :
_Obtenir la politique de gestion des mots de passe. Déterminer les méthodes d’authentification à l’application (directement sur l’application, SSO, …)._`,
              hasChildren: false,
              inputs: [
                {
                  id: "APD6 Procédure formalisée",
                  label:
                    "Existe-il une procédure formalisée (écrite) de gestion des mots de passe ?",
                  type: "radio",
                  name: "radio_15",
                  input_id: "radio_15",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "OUI",
                      description:
                        "Il existe une procédure formalisée de gestion des mots de passe ",
                      value: "OUI",
                      id: 61,
                    },
                    {
                      label: "NON",
                      description:
                        "Il n'existe pas de procédure formalisée de gestion des mots de passe ",
                      value: "NON",
                      id: 62,
                    },
                  ],
                },
                {
                  id: "APD6 Détails du processus de gestion des mots de passe - Description",
                  label: `
### Détails du processus de gestion des mots de passe
D’après la procédure ou par entretien :
* _Décrire la politique de gestion des mots de passe._`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder: "Processus de gestion des mots de passe",
                  description:
                    "Description du processus de gestion des mots de passe : ",
                  class: "w-full",
                },
                {
                  id: "APD6 Détails du processus de revue des comptes - Efficacité opérationnelle",
                  label: `
### Analyse du paramétrage des mots de passe permettant d’accéder à l’application :
* _Obtenir la preuve du paramétrage des mots de passe._
* _Comparer les paramètres avec la politique de mots de passe [recommandée par l’ANSII] (https://cyber.gouv.fr/bonnes-pratiques-protegez-vous#:~:text=Cr%C3%A9ez%20un%20mot%20de%20passe%20suffisamment%20long%2C%20complexe%20et%20inattendu%20%3A%20de%208%20caract%C3%A8res%20minimum%20et%20contenant%20des%20minuscules%2C%20des%20majuscules%2C%20des%20chiffres%20et%20des%20caract%C3%A8res%20sp%C3%A9ciaux) :_

|    Point de paramétrage    |     Critère reccomandé    |
| -------------------------- |:-------------------------:|
| Longueur minimale          |  8 caractères             |
| Durée de vie maximale      |  1 jour                   |
| Complexité du mot de passe |  Oui                      |
|    - 1 chiffre             |  Oui                      |
|    - 1 caractère spécial   |  Oui                      |
|    - 1 majuscule           |  Oui                      |
|    - 1 minuscule           |  Oui                      |
| Historique du mot de passe |  5 derniers               |
| Changement du mot de passe |  A la première connexion  |
| Verrouillage du compte     |  Après 3 tentatives       |
`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Détails des différentes étapes de l'analyse et des exceptions, si nécessaire :",
                  description:
                    "Attacher les pièces jointes : fichiers bruts et résultats des contrôles : ",
                  class: "w-full",
                },
                {
                  id: "APD6 Contrôle effectif",
                  suivi: true,
                  label: "Le contrôle est-il effectif ?",
                  type: "radio",
                  name: "radio_16",
                  input_id: "radio_16",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "OUI",
                      description: "Le contrôle est effectif",
                      value: "OUI",
                      id: 61,
                    },
                    {
                      label: "NON",
                      description: "Le contrôle n'est pas effectif",
                      value: "NON",
                      id: 62,
                    },
                  ],
                },
                {
                  id: app_id + "APD6 niveau de criticité",
                  suivi: true,
                  label: "Quel est le niveau de criticité de ce contrôle ?",
                  type: "radio",
                  name: "radio_17",
                  input_id: "radio_17",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "Faible",
                      description: "Le niveau de criticité est faible",
                      value: "Faible",
                      id: 61,
                    },
                    {
                      label: "Modéré",
                      description: "Le niveau de criticité est modéré",
                      value: "Modéré",
                      id: 62,
                    },
                    {
                      label: "Elevé",
                      description: "Le niveau de criticité est élevé",
                      value: "Elevé",
                      id: 63,
                    },
                    {
                      label: "Non Applicable",
                      description:
                        "Aucun niveau de criticité n'est applicable sur ce point de contrôle",
                      value: "Non Applicable",
                      id: 64,
                    },
                  ],
                },
                {
                  id: "APD6 Détails du processus de gestion des mots de passe - Analyse d'impact",
                  label: `
### Analyse d'impact pour ce point de contrôle :
Décrire l’impact de cette criticité sur les états financiers et la façon dont le risque est mitigé `,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Si le contrôle n'est pas effectif, décrire l'impact de ce point de contrôle sur les états financiers",
                  description: " ",
                  class: "w-full",
                },
              ],
            },
          ],
        },
        {
          id: app_id + ".2",
          name: "Programs & Changes",
          subtitle: "Conduite du Changement",
          description: "Description de l'engagement",
          hasChildren: true,
          children: [
            {
              id: app_id + "19",
              name: "CM1 - Suivi des changements",
              subtitle: "CM1 - Changes Management 1",
              description: `
### Description du point de contrôle :
_Les changements et les évolutions doivent faire l’objet d’une demande formelle. Ils doivent ensuite être testés et approuvés avant le déploiement._
### Risques du point de contrôle :
* _Non maîtrise des évolutions des systèmes d'information,_
* _Régression des systèmes,_
* _Mise en production de changements non conformes,_
* _Erreurs dues à des modifications insuffisamment documentées ou non testées._
### Conception du contrôle :
1. _Déterminer s’il existe une procédure formalisée de gestion des changements et des évolutions._
2. _Obtenir et examiner la procédure le cas échéant, et comprendre le processus opérationnel suivi pour :_
  1. _la gestion des demandes de changements,_
  2. _la réalisation,_
  3. _la documentation des tests et la validation avant la mise en production._`,
              hasChildren: false,
              inputs: [
                {
                  id: "CM1 Procédure formalisée",
                  label:
                    "Existe-il une procédure formalisée (écrite) de gestion des changements dans l'application ?",
                  type: "radio",
                  name: "radio_18",
                  input_id: "radio_18",
                  description: "",
                  class: "w-full",
                  options: [
                    {
                      label:
                        "OUI, Il existe une procédure formalisée de gestion des changements",
                      description:
                        "Il existe une procédure formalisée de gestion des changements",
                      value: "OUI",
                      id: 61,
                    },
                    {
                      label:
                        "NON, Il n'existe pas de procédure formalisée de gestion des changements ",
                      description:
                        "Il n'existe pas de procédure formalisée de gestion des changements",
                      value: "NON",
                      id: 62,
                    },
                    {
                      label:
                        "Il existe un processus clairement établi de gestion des changements mais qui n'a pas été formalisé",
                      description:
                        "Il existe un processus clairement établi de gestion des changements mais qui n'a pas été formalisé",
                      value: "NON",
                      id: 63,
                    },
                  ],
                },
                {
                  id: "CM1 - Conception du processus de changements - Description",
                  label: `
### Conception du processus de changements
* _Quels sont les types de changements ?_
* _ Qui sont les personnes habilitées à demander, valider, réaliser, tester et mettre en production ces changements ?_
* _Comment sont tracés et documentés les changements ?_
* _Comment sont testés les changements ?_
* _Comment sont validés les mises en productions ?_ `,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder: "Processus de gestion des changements",
                  description:
                    "Description du processus de gestion des changements : ",
                  class: "w-full",
                },
                {
                  id: "CM1 - Processus de gestion des changements - Efficacité opérationnelle",
                  label: `
### Analyse du processus de gestion des changements:
1. _Obtenir la liste des changements réalisés dans l'application sur l'exercice_
2. _Réaliser un échantillon et :
  1._Obtenir l'évidence de demande du changement_
  2._Obtenir la preuve des tests effectués et validés
  3._Obtenir la validation du passage en production `,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Détails des différentes étapes de l'analyse et des exceptions, si nécessaire :",
                  description:
                    "Attacher les pièces jointes : fichiers bruts et résultats des contrôles : ",
                  class: "w-full",
                },

                {
                  id: "CM1 Contrôle effectif",
                  suivi: true,
                  label: "Le contrôle est-il effectif ?",
                  type: "radio",
                  name: "radio_19",
                  input_id: "radio_19",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "OUI, le contrôle est effecctif",
                      description: "Le contrôle est effectif",
                      value: "OUI",
                      id: 61,
                    },
                    {
                      label: "NON, le contrôle n'est pas effectif",
                      description: "Le contrôle n'est pas effectif",
                      value: "NON",
                      id: 62,
                    },
                  ],
                },
                {
                  id: "CM1 niveau de criticité",
                  suivi: true,
                  label: "Quel est le niveau de criticité de ce contrôle ?",
                  type: "radio",
                  name: "radio_20",
                  input_id: "radio_20",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "Faible",
                      description: "Le niveau de criticité est faible",
                      value: "Faible",
                      id: 61,
                    },
                    {
                      label: "Modéré",
                      description: "Le niveau de criticité est modéré",
                      value: "Modéré",
                      id: 62,
                    },
                    {
                      label: "Elevé",
                      description: "Le niveau de criticité est élevé",
                      value: "Elevé",
                      id: 63,
                    },
                  ],
                },
                {
                  id: "CM1 Détails du processus de gestion des changements - Analyse d'impact",
                  label: `
### Analyse d'impact pour ce point de contrôle :
_Décrire l’impact de cette criticité sur les états financiers et la façon dont le risque est mitigé_`,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Si le contrôle n'est pas effectif, décrire l'impact de ce point de contrôle sur les états financiers",
                  description: " ",
                  class: "w-full",
                },
                {
                  id: "CM1 Détails du processus de gestion des changements - Recommandations",
                  label: `
### Recommandations pour ce point de contrôle :
_Exemples :_
* _Ajouter une recommandation sur la création d’un processus fiable de gestion des changements
* _Ajouter une recommandation sur la formalisation d’une procédure `,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder: "",
                  description: " ",
                  class: "w-full",
                },
              ],
            },
            {
              id: app_id + "20",
              name: "CM2 - Ségrégation des rôles",
              subtitle:
                "CM2 - Ségrégation des tâches, processus de gestion des changements",
              description: `
### Description du point de contrôle :
_Les fonctions de développement et de mise en production sont séparées pour permettre de s'assurer que seuls les changements validés sont mis en production._
### Risques du point de contrôle :
* _Risque de non-maîtrise des évolutions du systèmes d'information_
* _Risque de régression_
* _Risque de mise en production d'évolutions frauduleuses ou non conformes_
### Conception du point de contrôle :
_Obtenir la procédure de gestion des changements. La procédure devra couvrir les processus de dévellopement et de mise en production d'évolutions._`,
              hasChildren: false,
              inputs: [
                {
                  id: "CM2 Procédure formalisée",
                  label: `
### Existe-il une procédure formalisée (écrite) décrivant la séparation des activités de développement et de mise en production ?`,
                  type: "radio",
                  name: "radio_20",
                  input_id: "radio_20",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "OUI",
                      description:
                        "Il existe une procédure formalisée de gestion des changements décrivant la séparation des tâches de mises en production ",
                      value: "OUI",
                      id: 61,
                    },
                    {
                      label: "NON",
                      description:
                        "Il n'existe pas de procédure formalisée de gestion des changements décrivant la séparation des tâches de mises en production",
                      value: "NON",
                      id: 62,
                    },
                  ],
                },
                {
                  id: "CM2 Détails du processus de séparation des tâches - Description",
                  label: `
### Description du processus de séparation des tâches
* _Comment sont réalisées les mises en production de changements_
* _Existe t-il plusieurs environnements spécifiques (développement, test, qualité, préproduction, production) ?_
* _A défaut d'une séparation stricte des rôles de développement et de mise en production, une revue du code par des pairs a t-elle lieu avant toute mise en production ?_ `,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Processus de séparation des activités de développement et de mise en production",
                  description:
                    "Description du processus de séparation des activités de développement et de mise en production : ",
                  class: "w-full",
                },
                {
                  id: "CM2 Détails du processus de séparation des tâches - Efficacité opérationnelle",
                  label: `
### Analyse des désactivation d'accès aux systèmes
* _Obtenir la liste des changements ayant eu lieu sur l'exercice et réaliser un échantillon._
* _S'assurer qu'une séparation des tâches a bien été mise en place lors des mises en production ayant eu lieu au cours de l'exercice._
* _S'assurer qu'il n'est pas possible de dévellopper directement dans l'environnement de production `,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Détails des différentes étapes de l'analyse et des exceptions, si nécessaire :",
                  description:
                    "Attacher les pièces jointes : fichiers bruts et résultats des contrôles : ",
                  class: "w-full",
                },
                {
                  id: "CM2 Contrôle effectif",
                  suivi: true,
                  label: `
### Le contrôle est-il effectif ?`,
                  type: "radio",
                  name: "radio_21",
                  input_id: "radio_21",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "OUI",
                      description: "Le contrôle est effectif",
                      value: "OUI",
                      id: 61,
                    },
                    {
                      label: "NON",
                      description: "Le contrôle n'est pas effectif",
                      value: "NON",
                      id: 62,
                    },
                  ],
                },
                {
                  id: "CM2 niveau de criticité",
                  suivi: true,
                  label: `
### Quel est le niveau de criticité de ce contrôle ?`,
                  type: "radio",
                  name: "radio_22",
                  input_id: "radio_22",
                  description: " ",
                  class: "w-full",
                  options: [
                    {
                      label: "Faible",
                      description: "Le niveau de criticité est faible",
                      value: "Faible",
                      id: 61,
                    },
                    {
                      label: "Modéré",
                      description: "Le niveau de criticité est modéré",
                      value: "Modéré",
                      id: 62,
                    },
                    {
                      label: "Elevé",
                      description: "Le niveau de criticité est élevé",
                      value: "Elevé",
                      id: 63,
                    },
                    {
                      label: "Non Applicable",
                      description:
                        "Aucun niveau de criticité n'est applicable sur ce point de contrôle",
                      value: "Non Applicable",
                      id: 64,
                    },
                  ],
                },
                {
                  id: "CM2 Détails du processus de séparation des tâches - Analyse d'impact",
                  label: `
### Analyse d'impact pour ce point de contrôle :
_Décrire l’impact de cette criticité sur les états financiers et la façon dont le risque est mitigé_ `,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder:
                    "Si le contrôle n'est pas effectif, décrire l'impact de ce point de contrôle sur les états financiers",
                  description: " ",
                  class: "w-full",
                },
                {
                  id: "APD2 Détail du processus de désactivation des accès - Recommandations",
                  label: `
### Recommandations pour ce point de contrôle
_Exemples :_
* _Ajouter une recommandation sur la création d’un processus fiable de séparation des rôles_
* _Ajouter une recommandation sur la formalisation d’une procédure de revue par des pairs_ `,
                  type: "textarea",
                  name: "textarea",
                  input_id: "textarea",
                  placeholder: "",
                  description: " ",
                  class: "w-full",
                },
              ],
            },
          ],
        },
      ],
    },
  ];
}

// Add inputs to screen
// addInputsToScreen("CBSist2UD1GRhvu41Hl4", inputs.inputs);
// deleteAllInputs();

// Create inputs and them to a screen
// Get inputs list, create a document for each one and store the id in the screen
const addInputsToScreen = async (screenId, inputsToAdd) => {
  try {
    // Fonction pour créer un input dans Firestore et retourner son ID
    const createInput = async (input) => {
      const inputRef = await addDoc(collection(db, "inputs"), input);
      return inputRef.id;
    };

    // Traiter les inputs et obtenir les ID de tous les inputs
    const inputIds = [];
    for (const input of inputsToAdd) {
      const inputId = await createInput(input);
      inputIds.push(inputId);
    }

    // Remplace les inputs par leurs ID dans l'écran
    const screenRef = doc(db, "screens", screenId);
    await updateDoc(screenRef, {
      inputs: inputIds,
    });

    console.log("Inputs added to screen with ID: ", screenId);
  } catch (e) {
    console.error("Error adding inputs to screen: ", e);
  }
};

// Delete all screens
const deleteAllScreens = async () => {
  try {
    const screensCollectionRef = collection(db, "screens");
    const querySnapshot = await getDocs(screensCollectionRef);

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
      console.log("Screen deleted with ID: ", doc.id);
    });
  } catch (e) {
    console.error("Error deleting screens: ", e);
  }
};

// Delete all inputs
const deleteAllInputs = async () => {
  try {
    const inputsCollectionRef = collection(db, "inputs");
    const querySnapshot = await getDocs(inputsCollectionRef);

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
      console.log("Input deleted with ID: ", doc.id);
    });
  } catch (e) {
    console.error("Error deleting inputs: ", e);
  }
};

// Add screens to an engagement
const addScreenToEngagement = async (engagementId, screensToAdd) => {
  try {
    // Fonction pour créer un écran dans Firestore et retourner son ID
    const createScreen = async (screen) => {
      const screenRef = await addDoc(collection(db, "screens"), screen);
      return screenRef.id;
    };

    // Fonction récursive pour traiter chaque écran et ses enfants
    const processScreens = async (screens, parentId = null) => {
      let ids = [];

      // Parcours de chaque élément
      for (const screen of screens) {
        let newScreen = { ...screen, parentId, children: [] };

        // Si l'écran a des enfants, traiter les enfants
        if (screen.hasChildren && screen.children?.length > 0) {
          const childIds = await processScreens(screen.children);
          newScreen.children = childIds;
        }

        const screenId = await createScreen(newScreen);
        ids.push(screenId);

        // Si l'écran a des inputs, les ajouter à l'écran
        if (screen.inputs && screen.inputs.length > 0) {
          await addInputsToScreen(screenId, screen.inputs);
        }
      }

      return ids;
    };

    // Traiter les écrans et obtenir les ID de tous les écrans de niveau supérieur
    const topScreenIds = await processScreens(screensToAdd);

    // Mettre à jour l'engagement avec les ID des écrans de niveau supérieur
    const engagementRef = doc(db, "engagements", engagementId);
    await updateDoc(engagementRef, {
      screens: arrayUnion(...topScreenIds),
    });

    console.log("Screens added to engagement with ID: ", engagementId);
  } catch (e) {
    console.error("Error adding screens to engagement: ", e);
  }
};

// Set screenLock by screen id
const setScreenLock = async (screenId, lock) => {
  try {
    const screenRef = doc(db, "screens", screenId);
    await updateDoc(screenRef, {
      locked: lock,
    });

    console.log("Screen locked with ID: ", screenId);
  } catch (e) {
    console.error("Error setting screen lock: ", e);
  }
};

// addScreenToEngagement("Gkt5x0EnIPX5FmideeEh", screens.screens);

// Delete cascade screens by screen id
const deleteCascadeScreens = async (screenId) => {
  try {
    // Fonction récursive pour supprimer un écran et ses enfants
    const deleteScreenWithChildren = async (screenId) => {
      const screenRef = doc(db, "screens", screenId);
      const screenSnap = await getDoc(screenRef);

      if (!screenSnap.exists()) {
        return;
      }

      let screen = { id: screenSnap.id, ...screenSnap.data() };

      if (screen.children && screen.children.length > 0) {
        for (const childId of screen.children) {
          await deleteScreenWithChildren(childId);
        }
      }

      await deleteDoc(screenRef);
      console.log("Screen deleted with ID: ", screenId);
    };

    await deleteScreenWithChildren(screenId);
  } catch (e) {
    console.error("Error deleting cascade screens: ", e);
  }
};

// Create an engagement
const createEngagement = async (details) => {
  const engagement = {
    client_id: details.client.id,
    type: details.type.nom,
    created_at: new Date(),
    last_modified: new Date(),
    creator_id: details.creator_id,
    organisation_id: details.organisation_id,
    screens: [],
    state: "En attente",
    description: details.description,
  };

  try {
    const docRef = await addDoc(collection(db, "engagements"), engagement);
    console.log("Document written with ID: ", docRef.id);
    // Add screens to the engagement
    await addScreenToEngagement(docRef.id, screens.screens);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

// Update an engagement
const updateEngagement = async (engagementId, details) => {
  try {
    const engagementRef = doc(db, "engagements", engagementId);
    await updateDoc(engagementRef, details);
    console.log("Document updated with ID: ", engagementId);
  } catch (e) {
    console.error("Error updating document: ", e);
  }
};

// Get all engagements
const getEngagements = async (resultsLimit, currentUser) => {
  if (!currentUser) {
    return [];
  }

  try {
    const engagementsCollectionRef = collection(db, "engagements");
    const engagementsQuery = query(
      engagementsCollectionRef,
      limit(resultsLimit),
      orderBy("created_at", "desc"),
    );
    const querySnapshot = await getDocs(engagementsQuery);

    let engagements = [];

    querySnapshot.forEach((doc) => {
      // Check if the engagement belongs to the current user's organisation
      if (
        doc.data().organisation_id &&
        doc.data().organisation_id === currentUser.organisation_id
      ) {
        engagements.push({ id: doc.id, ...doc.data() });
      }
    });

    // Add client name to each engagement
    for (let i = 0; i < engagements.length; i++) {
      const clientDoc = await getDoc(
        doc(db, "clients", engagements[i].client_id),
      );
      engagements[i].client = clientDoc.data();
    }

    // Add creator name to each engagement
    for (let i = 0; i < engagements.length; i++) {
      const creatorDoc = await getDoc(
        doc(db, "users", engagements[i].creator_id),
      );
      engagements[i].creator = creatorDoc.data();
    }

    // Parse firebase timestamp into react date
    for (let i = 0; i < engagements.length; i++) {
      engagements[i].created_at = engagements[i].created_at.toDate();
      engagements[i].last_modified = engagements[i].last_modified.toDate();
    }

    return engagements;
  } catch (error) {
    console.error("Error getting engagements: ", error);
    throw error;
  }
};

// Fonction récursive pour récupérer un écran et ses enfants
const getScreenWithChildren = async (screenId) => {
  const screenRef = doc(db, "screens", screenId);
  const screenSnap = await getDoc(screenRef);

  if (!screenSnap.exists()) {
    return null;
  }

  let screen = { id: screenSnap.id, ...screenSnap.data() };

  screen.uid = screenSnap.id;

  if (screen.children && screen.children.length > 0) {
    let children = [];
    for (const childId of screen.children) {
      const childScreen = await getScreenWithChildren(childId);
      if (childScreen) {
        children.push(childScreen);
      }
    }
    screen.children = children;
  }

  return screen;
};

// Get an engagement by its ID and other informations
const getEngagementById = async (engagementId, currentUser) => {
  try {
    // Récupérer l'engagement par son ID
    const engagementRef = doc(db, "engagements", engagementId);
    const engagementSnap = await getDoc(engagementRef);

    if (!engagementSnap.exists()) {
      console.log("No such engagement!");
      return { success: false, errorType: "ENGAGEMENT_NOT_FOUND" };
    }

    if (
      currentUser.organisation_id &&
      engagementSnap.data().organisation_id !== currentUser.organisation_id
    ) {
      console.log("Engagement does not belong to the user's organisation!");
      return { success: false, errorType: "ORG_MISMATCH" };
    }

    let engagement = { id: engagementSnap.id, ...engagementSnap.data() };

    // Retrieve client information
    const clientRef = doc(db, "clients", engagement.client_id);
    const clientSnap = await getDoc(clientRef);
    if (clientSnap.exists()) {
      engagement.client = { id: clientSnap.id, ...clientSnap.data() };
    }

    // Retrieve creator information
    const creatorRef = doc(db, "users", engagement.creator_id);
    const creatorSnap = await getDoc(creatorRef);
    if (creatorSnap.exists()) {
      engagement.creator = { id: creatorSnap.id, ...creatorSnap.data() };
    }

    // Récupérer les informations des écrans principaux et de leurs enfants
    if (engagement.screens && engagement.screens.length > 0) {
      let screensInfo = [];

      for (const screenId of engagement.screens) {
        const screen = await getScreenWithChildren(screenId);
        if (screen) {
          // Ajoute l'id du screen
          screen.uid = screenId;
          screensInfo.push(screen);
        }
      }

      engagement.screens = screensInfo;
    }

    return { success: true, engagement: engagement };
  } catch (error) {
    console.error("Error getting engagement by ID: ", error);
    throw error;
  }
};

// Get engagement by id
const getEngagementByIdLight = async (engagementId) => {
  try {
    const engagementRef = doc(db, "engagements", engagementId);
    const engagementSnap = await getDoc(engagementRef);

    if (!engagementSnap.exists()) {
      console.log("No such engagement!");
      return null;
    }

    let engagement = { id: engagementSnap.id, ...engagementSnap.data() };

    // Retrieve client information
    const clientRef = doc(db, "clients", engagement.client_id);
    const clientSnap = await getDoc(clientRef);
    if (clientSnap.exists()) {
      engagement.client = { id: clientSnap.id, ...clientSnap.data() };
    }

    return engagement;
  } catch (error) {
    console.error("Error getting engagement by ID: ", error);
    throw error;
  }
};

// Get inputs from ids
const getInputsByIds = async (inputIds) => {
  try {
    let inputs = [];

    for (const inputId of inputIds) {
      const inputRef = doc(db, "inputs", inputId);
      const inputSnap = await getDoc(inputRef);

      if (inputSnap.exists()) {
        inputs.push({
          id: inputSnap.id,
          ...inputSnap.data(),
          uid: inputId,
        });
      }
    }

    return inputs;
  } catch (error) {
    console.error("Error getting inputs by IDs: ", error);
    throw error;
  }
};

// Return a liste of screens with inputs data from a list of screens
const getScreensWithInputs = async (screens) => {
  try {
    let screensWithInputs = [];

    for (const screen of screens) {
      const screenRef = doc(db, "screens", screen);
      const screenSnap = await getDoc(screenRef);

      if (screenSnap.exists()) {
        let screenData = { id: screenSnap.id, ...screenSnap.data() };

        // Get inputs from screen
        const inputs = await getInputsByIds(screenData.inputs);
        screenData.inputs = inputs;

        screensWithInputs.push(screenData);
      }
    }

    return screensWithInputs;
  } catch (error) {
    console.error("Error getting screens with inputs: ", error);
    throw error;
  }
};

const updateInputValueById = async (inputId, value) => {
  try {
    const inputRef = doc(db, "inputs", inputId);
    await updateDoc(inputRef, {
      value: value,
    });

    console.log("Input updated with ID: ", inputId);
  } catch (e) {
    console.error("Error updating input: ", e);
  }
};

// Create application and add the id in the engagement application list
const addApplicationToEngagement = async (engagementId, application) => {
  try {
    const applicationRef = await addDoc(
      collection(db, "applications"),
      application,
    );

    // Add application screens to the engagement
    await addScreenToEngagement(
      engagementId,
      appScreens(application.name + " - APP", applicationRef.id),
    );

    const engagementRef = await doc(db, "engagements", engagementId);
    // Get screens from engagement
    const engagementSnap = await getDoc(engagementRef);
    let engagement = { id: engagementSnap.id, ...engagementSnap.data() };

    // Get the last scrren id from engagement screens list
    const lastScreenId = engagement.screens[engagement.screens.length - 1];

    // Add the last screen id to the application
    await updateDoc(applicationRef, {
      screen_id: lastScreenId,
    });

    // Add the application id to the engagement applications list
    await updateDoc(engagementRef, {
      applications: arrayUnion(applicationRef.id),
    });

    console.log("Application added to engagement with ID: ", engagementId);
  } catch (e) {
    console.error("Error adding application to engagement: ", e);
  }
};

// Remove application id from engagement application list
const removeApplicationFromEngagement = async (
  engagementId,
  applicationId,
  screen_id,
) => {
  try {
    const engagementRef = doc(db, "engagements", engagementId);
    await updateDoc(engagementRef, {
      applications: arrayRemove(applicationId),
    });

    console.log("Application removed from engagement with ID: ", engagementId);

    // Delete cascade screens by screen id
    await deleteCascadeScreens(screen_id);
  } catch (e) {
    console.error("Error removing application from engagement: ", e);
  }
};

// Get applications ids of an engamgement
const getApplicationsIds = async (engagementId) => {
  try {
    const engagementRef = doc(db, "engagements", engagementId);
    const engagementSnap = await getDoc(engagementRef);

    if (!engagementSnap.exists()) {
      console.log("No such engagement!");
      return null;
    }

    return engagementSnap.data().applications;
  } catch (error) {
    console.error("Error getting applications IDs: ", error);
    throw error;
  }
};

// Get application by id
const getApplicationById = async (applicationId) => {
  try {
    const applicationRef = doc(db, "applications", applicationId);
    const applicationSnap = await getDoc(applicationRef);

    if (!applicationSnap.exists()) {
      console.log("No such application!");
      return null;
    }

    return { id: applicationSnap.id, ...applicationSnap.data() };
  } catch (error) {
    console.error("Error getting application by ID: ", error);
    throw error;
  }
};

// Get applications by a list of ids
const getApplicationsByIds = async (applicationIds) => {
  try {
    let applications = [];

    for (const application of applicationIds) {
      const applicationRef = doc(db, "applications", application);
      const applicationSnap = await getDoc(applicationRef);

      if (applicationSnap.exists()) {
        applications.push({
          id: applicationSnap.id,
          ...applicationSnap.data(),
        });
      }
    }

    return applications;
  } catch (error) {
    console.error("Error getting applications by IDs: ", error);
    throw error;
  }
};

// Add file from input to storage
export const uploadFileToFirebase = async (file, path, input_uid) => {
  const storageRef = ref(storage, `${path}/${file.name}`);
  try {
    const snapshot = await uploadBytes(storageRef, file);
    // Get url of the uploaded file
    const url = await getDownloadURL(snapshot.ref);
    // Update input field nammed files_list add object with file name and timestamp
    const inputRef = doc(db, "inputs", input_uid);
    await updateDoc(inputRef, {
      files_list: arrayUnion({
        name: file.name,
        added_date: new Date(),
        url: url,
      }),
    });
    return snapshot;
  } catch (error) {
    throw new Error("Error uploading file: " + error.message);
  }
};

export const downloadFileFromStorage = async (storageUrl) => {
  try {
    // Exemple d'utilisation de Firebase Storage pour télécharger un fichier
    const storageRef = ref(storage, storageUrl);
    const file = await getDownloadURL(storageRef);
    return file;
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};

// Update input by id
export const updateInputById = async (inputId, data) => {
  try {
    const inputRef = doc(db, "inputs", inputId);
    await updateDoc(inputRef, data);
    console.log("Input updated with ID: ", inputId);
  } catch (e) {
    console.error("Error updating input: ", e);
  }
};

// Update files_list by input id and file position in list
export const updateFilesListByInputId = async (inputId, data, index) => {
  try {
    const inputRef = doc(db, "inputs", inputId);
    const inputSnap = await getDoc(inputRef);
    const input = { id: inputSnap.id, ...inputSnap.data() };
    const filesList = input.files_list;
    data.added_date != ""
      ? (data.added_date = new Date(data.added_date))
      : (data.added_date = "");
    filesList[index] = data;
    await updateDoc(inputRef, {
      files_list: filesList,
    });
    console.log("Files list updated with ID: ", inputId);
  } catch (e) {
    console.error("Error updating files list: ", e);
  }
};

// Add files from a list of files selected to files list by input id
export const addFilesToFilesListByInputId = async (inputId, data) => {
  try {
    const inputRef = doc(db, "inputs", inputId);
    const inputSnap = await getDoc(inputRef);
    const input = { id: inputSnap.id, ...inputSnap.data() };
    let filesList = [];
    input.files_list ? (filesList = input.files_list) : (filesList = []);
    data.map((file) => {
      filesList.push(file);
    });
    await updateDoc(inputRef, {
      files_list: filesList,
    });
    console.log("Files added to files list with ID: ", inputId);
  } catch (e) {
    console.error("Error adding files to files list: ", e);
  }
};

// Delete file from input list
export const deleteFileFromInput = async (inputId, index) => {
  try {
    const inputRef = doc(db, "inputs", inputId);
    const inputSnap = await getDoc(inputRef);
    const input = { id: inputSnap.id, ...inputSnap.data() };
    const filesList = input.files_list;
    filesList.splice(index, 1);
    await updateDoc(inputRef, {
      files_list: filesList,
    });
    console.log("File deleted from input with ID: ", inputId);
  } catch (e) {
    console.error("Error deleting file from input: ", e);
  }
};

// Get all filenames of files from engagement
export const getFilesByEngagementId = async (engagementId) => {
  const folderRef = ref(storage, "Engagements/" + engagementId);
  try {
    const result = await listAll(folderRef);

    const filesPromises = result.items.map(async (itemRef) => {
      const url = await getDownloadURL(itemRef);
      return { name: itemRef.name, url };
    });

    const files = await Promise.all(filesPromises);

    return files;
  } catch (error) {
    console.error("Error getting files names: ", error);
  }
};

export const getFollowUpByEngagementId = async (engagementId) => {
  // Liste des inputs à suivre
  const inputsToFollowUp = [
    "APD1 Contrôle effectif",
    "APD1 niveau de criticité",
  ];

  // Récupérer les IDs des applications liées à l'engagement
  const applicationsIds = await getApplicationsIds(engagementId);

  // Fonction récursive pour vérifier si l'écran et ses enfants contiennent des inputs à suivre
  const checkFollowUp = async (screen) => {
    let screensList = [];
    if (screen.inputs && screen.inputs.length > 0) {
      // Récupérer les inputs associés à l'écran
      const inputs = await getInputsByIds(screen.inputs);
      const inputsToKeep = inputs.filter((input) => input.suivi === true);

      // Si on trouve des inputs à suivre, on garde cet écran
      if (inputsToKeep.length > 0) {
        screensList.push({ ...screen, inputs: inputsToKeep });
      }
    }

    // Vérifier les enfants de l'écran
    if (screen.children && screen.children.length > 0) {
      for (const child of screen.children) {
        const childScreens = await checkFollowUp(child);
        screensList = screensList.concat(childScreens);
      }
    }
    return screensList;
  };

  // Récupérer les données des applications et des écrans en parallèle
  const followUpInputs = await Promise.all(
    applicationsIds.map(async (applicationId) => {
      const application = await getApplicationById(applicationId);
      const screens = await getScreenWithChildren(application.screen_id);

      // Vérifier les écrans de cette application
      const screensList = await checkFollowUp(screens);

      // Retourner les données de suivi pour cette application
      return {
        applicationData: application,
        screens: screensList,
      };
    }),
  );

  return followUpInputs;
};

// Get favorite engagments informations by user Id
export const getFavoritesByUserId = async (userId) => {
  try {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      console.log("No such user!");
      return null;
    }

    const user = { id: userSnap.id, ...userSnap.data() };
    const favorites = user.favoriteEngagements || [];

    let favoritesList = [];
    for (const favorite of favorites) {
      const engagement = await getEngagementByIdLight(favorite);
      favoritesList.push(engagement);
    }

    return favoritesList;
  } catch (error) {
    console.error("Error getting favorites by user ID: ", error);
    throw error;
  }
};

export {
  createEngagement,
  getEngagements,
  getEngagementById,
  getInputsByIds,
  updateInputValueById,
  addApplicationToEngagement,
  removeApplicationFromEngagement,
  getApplicationById,
  getApplicationsByIds,
  getApplicationsIds,
  setScreenLock,
  updateEngagement,
  getScreensWithInputs,
};
