import React, { useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
// import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { addTeam } from "../services/teamServices";
import { getAllUsers } from "../services/userServices";

import SuccessAlert from "./alerts/successalert";

import { Treadmill } from "./loading/treadmill";

import { useNavigate } from "react-router-dom";

const TeamCreateForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPersons, setSelectedPersons] = useState([]);
  const [users, setUsers] = useState([]); // State for users
  const [isCreated, setIsCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersData = await getAllUsers();
        setUsers(usersData);
      } catch (error) {
        console.error("Error fetching users:", error);
        // Handle error
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  function isSelected(value) {
    return selectedPersons.find((el) => el === value.id) ? true : false;
  }

  function handleSelect(value) {
    if (!isSelected(value)) {
      setSelectedPersons([...selectedPersons, value.id]);
    } else {
      handleDeselect(value.id);
    }
    setIsOpen(true);
  }

  function handleDeselect(valueId) {
    const selectedPersonsUpdated = selectedPersons.filter(
      (id) => id !== valueId,
    );
    setSelectedPersons(selectedPersonsUpdated);
    setIsOpen(true);
  }

  const getNameById = (id) => {
    const user = users.find((user) => user.id === id);
    return user ? user.name : null;
  };

  const getFirstnameById = (id) => {
    const user = users.find((user) => user.id === id);
    return user ? user.firstname : null;
  };

  const handleSubmit = async (event) => {
    setIsCreating(true);
    event.preventDefault();
    const teamData = {
      teamName: event.target.company.value,
      description: event.target.description.value,
      members: selectedPersons,
      createdAt: new Date(),
    };

    try {
      const docId = await addTeam(teamData);
      setIsCreating(false);
      // redirect to equipe page
      navigate("/equipe");
      setIsCreated(true);
      // Handle post-submission logic
    } catch (error) {
      console.error("Failed to create team: ", error);
      setIsCreating(false);
      // Handle errors
    }
  };

  // Fonction pour fermer l'alerte de succès
  const handleDismiss = () => {
    setIsCreated(false);
  };

  return (
    <div className="isolate bg-gray-900 px-6 py-24 sm:py-10 lg:px-8">
      {isCreated && (
        <SuccessAlert
          onDismiss={handleDismiss}
          text="Equipe créée avec succès !"
        />
      )}
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Création d&apos;une équipe
        </h2>
        <p className="mt-2 text-lg leading-8 text-gray-400">
          Renseignez ici les informations relatives à l&apos;équipe.
        </p>
      </div>
      <form className="mx-auto mt-16 max-w-xl sm:mt-20" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <label
              htmlFor="equipe"
              className="block text-sm font-semibold leading-6 text-white"
            >
              Nom de l&apos;équipe
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="company"
                id="company"
                autoComplete="organization"
                className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="description"
              className="block text-sm font-semibold leading-6 text-white"
            >
              Description
            </label>
            <div className="mt-2.5">
              <textarea
                name="description"
                id="description"
                rows={4}
                className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                defaultValue={""}
              />
            </div>
          </div>
          <Listbox
            as="div"
            className="space-y-1 sm:col-span-2"
            value={selectedPersons}
            onChange={(value) => handleSelect(value)}
            open={isOpen}
          >
            {() => (
              <>
                <Listbox.Label className="block text-sm font-semibold leading-6 text-white">
                  Membres de l&apos;équipe
                </Listbox.Label>
                <div className="relative">
                  <span className="inline-block w-full rounded-md shadow-sm">
                    <Listbox.Button
                      className="cursor-default relative w-full rounded-md border border-gray-700 bg-gray-800 text-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                      onClick={() => setIsOpen(!isOpen)}
                      open={isOpen}
                    >
                      <span className="block truncate">
                        {selectedPersons.length < 1
                          ? "Select persons"
                          : `Selected persons (${selectedPersons.length})`}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <svg
                          className="h-5 w-5 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path
                            d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </Listbox.Button>
                  </span>

                  <Transition
                    unmount={false}
                    show={isOpen}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="absolute mt-1 w-full rounded-md bg-gray-800 shadow-lg"
                  >
                    <Listbox.Options
                      static
                      className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                    >
                      {users.map((person) => {
                        const selected = isSelected(person);
                        return (
                          <Listbox.Option key={person.id} value={person}>
                            {({ active }) => (
                              <div
                                className={`${
                                  active
                                    ? "text-white bg-blue-600 flex items-center"
                                    : "text-white flex items-center"
                                } cursor-default select-none relative py-2 pl-8 pr-4 flex items-center`}
                              >
                                <img
                                  src={person.profilePicUrl}
                                  alt=""
                                  className="h-5 w-5 flex-shrink-0 rounded-full"
                                />
                                <span
                                  className={`${
                                    selected
                                      ? "font-semibold ml-3"
                                      : "font-normal ml-3"
                                  } block truncate`}
                                >
                                  {person.name + " " + person.firstname}
                                </span>
                                {selected && (
                                  <span
                                    className={`${
                                      active ? "text-white" : "text-blue-600"
                                    } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                  >
                                    <svg
                                      className="h-5 w-5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                )}
                              </div>
                            )}
                          </Listbox.Option>
                        );
                      })}
                    </Listbox.Options>
                  </Transition>
                  <div className="pt-1 text-sm text-gray-400">
                    {selectedPersons.length > 0 && (
                      <>
                        Selected persons:{" "}
                        {selectedPersons.length > 0 && (
                          <>
                            {selectedPersons.map(
                              (personSelected) =>
                                getFirstnameById(personSelected) +
                                " " +
                                getNameById(personSelected) +
                                ", ",
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </Listbox>
        </div>

        {isCreating ? (
          <div className="mt-10">
            <div
              type="button"
              className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <Treadmill color="black" size="30" speed="0.5" />
            </div>
          </div>
        ) : (
          <div className="mt-10">
            <button
              type="submit"
              className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Créer
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default TeamCreateForm;
