import React, { useState } from "react";

import {
  Navbar,
  SearchBar,
  EngagementSection,
  ActivitySection,
} from "../components";
import ClientsSection from "../components/client/clientssection";

const Clients = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [search, setSearch] = useState(""); // état pour stocker la recherche

  return (
    <div className="h-full">
      {/* LEFT PANEL */}
      <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* MAIN PANEL */}
      <div className="xl:pl-72 h-full">
        <SearchBar setSidebarOpen={setSidebarOpen} setSearch={setSearch} />
        <ClientsSection search={search} />
      </div>
    </div>
  );
};

export default Clients;
