import React from "react";

import logo from "../assets/images/logo.svg";
import { useAuth } from "../contexts/AuthContext";

const NavbarLogo = () => {
  const { currentUser } = useAuth();

  return (
    <a href="/">
      <div className="flex h-16 shrink-0 items-center justify-center border-b-[1px] border-white/5">
        <img className="h-14 w-auto" src={logo} alt="Oditum" />
      </div>
    </a>
  );
};

export default NavbarLogo;
