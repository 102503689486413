import { React, Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FolderOpenIcon } from "@heroicons/react/solid";
import SelectDocuments from "../selects/selectdocuments";
import {
  uploadFileToFirebase,
  getFilesByEngagementId,
  addFilesToFilesListByInputId,
} from "../../services/engagementServices";
import { Treadmill } from "../loading/treadmill";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

export default function DocumentAddModal({
  open,
  setOpen,
  setLoading,
  data,
  updateData,
}) {
  const [selectedFile, setSelectedFile] = useState(null);
  // Get engagement id in url parameters
  const urlParams = new URLSearchParams(window.location.search);
  const { engagementId } = useParams();
  const [documents, setFiles] = useState([{}]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const documents = await getFilesByEngagementId(engagementId);
        setFiles(documents);
      } catch (error) {
        console.error("Error fetching documents: ", error);
      }
    };
    fetchFiles();
  }, [engagementId]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    // Si des documents existants ont été sélectionnés
    if (selectedDocuments.length > 0) {
      try {
        await addFilesToFilesListByInputId(data.uid, selectedDocuments);
        console.log("Documents updated successfully");
      } catch (error) {
        console.error("Error updating documents:", error);
      }
    }

    // Si un fichier a été chargé
    if (selectedFile) {
      try {
        await uploadFileToFirebase(
          selectedFile,
          "Engagements/" + engagementId + "/",
          data.uid,
        );
        console.log("File uploaded successfully");
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }

    updateData();
    setOpen(false);
    setLoading(true);
    setIsSubmitting(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-1/2 sm:p-6">
              <h1 className="text-2xl font-bold text-white">
                Ajout d&apos;un document
              </h1>
              <div className="flex items-start justify-center h-full mt-4">
                <div className="w-1/2 h-full flex-1 p-4">
                  <h3 className="text-lg text-white w-full text-center">
                    A partir de l&apos;explorateur de fichier
                  </h3>

                  <div className="relative border-dotted h-48 mt-2 rounded-lg border-dashed border-2 border-blue-700 bg-gray-100 flex justify-center items-center ">
                    <div className="absolute">
                      <div className="flex flex-col items-center">
                        <FolderOpenIcon className="size-24 text-blue-700" />
                        <span className="block text-gray-400 font-normal">
                          Joindre le document ici
                        </span>
                      </div>
                    </div>

                    <input
                      type="file"
                      className="h-full w-full opacity-0 cursor-pointer"
                      id="file"
                      name="file"
                      accept=""
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <div className="w-1/2 flex-1 flex flex-col justify-between p-4">
                  <h3 className="text-lg text-white w-full text-center">
                    Référencer un document existant
                  </h3>
                  <SelectDocuments
                    documents={documents}
                    selectedDocuments={selectedDocuments}
                    setSelectedDocuments={setSelectedDocuments}
                  />
                </div>
              </div>
              <div className="flex gap-2 justify-end">
                <button
                  type="button"
                  className="rounded-md bg-white/10 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                  onClick={() => setOpen(false)}
                >
                  Annuler
                </button>
                {isSubmitting ? (
                  <div
                    type="button"
                    className="flex justify-center align-center w-28 rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    <Treadmill color="black" size="30" speed="0.5" />
                  </div>
                ) : (
                  <button
                    type="button"
                    className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    onClick={handleSubmit}
                  >
                    Enregistrer
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

DocumentAddModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
  data: PropTypes.object,
  updateData: PropTypes.func,
};
