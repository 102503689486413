import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FolderIcon,
  HomeIcon,
  CogIcon,
  UserGroupIcon,
  BriefcaseIcon,
} from "@heroicons/react/outline";

import { useAuth } from "../contexts/AuthContext";

import { getFavoriteClientsInfo } from "../services/clientServices";
import { getFavoritesByUserId } from "../services/engagementServices";

import { NavbarWeb, NavbarMobile } from "./index";
import { isDisabled } from "@testing-library/user-event/dist/cjs/utils/index.js";

const navigation = [
  {
    id: 1,
    nom: "Accueil",
    href: "/",
    icon: HomeIcon,
    current: false,
    initial: "SS",
  },
  {
    id: 2,
    nom: "Engagements",
    href: "/engagements",
    icon: FolderIcon,
    current: false,
    initial: "SS",
  },
  {
    id: 3,
    nom: "Clients",
    href: "/clients",
    icon: BriefcaseIcon,
    current: false,
    initial: "SS",
  },
  {
    id: 4,
    nom: "Équipe",
    href: "/equipe",
    icon: UserGroupIcon,
    current: false,
    initial: "SS",
  },
  {
    id: 5,
    nom: "Réglages",
    href: "/reglages",
    icon: CogIcon,
    current: false,
    initial: "SS",
    isDisabled: true,
  },
];

const Navbar = ({ sidebarOpen, setSidebarOpen }) => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [clients, setClients] = useState([]); // état pour stocker les clients
  const [engagements, setEngagements] = useState([]); // état pour stocker les engagements
  const { currentUser } = useAuth();

  useEffect(() => {
    // Mise à jour du chemin actuel lorsqu'il change
    const handlePathChange = () => setCurrentPath(window.location.pathname);
    window.addEventListener("popstate", handlePathChange);

    // Récupération des clients
    const fetchClients = async () => {
      const clientsData = await getFavoriteClientsInfo(currentUser.uid);
      clientsData.map((client) => {
        client.href = `/client/${client.id}`;
        return client;
      });
      setClients(clientsData);
    };

    // Récupération des engagements
    const fetchEngagements = async () => {
      const engagementsData = await getFavoritesByUserId(currentUser.uid);
      engagementsData.map((engagement) => {
        engagement.href = `/engagement/${engagement.id}`;
        engagement.nom = engagement.client.nom + " / " + engagement.type;
        return engagement;
      });
      setEngagements(engagementsData);
    };

    fetchEngagements();
    fetchClients();

    return () => {
      window.removeEventListener("popstate", handlePathChange);
    };
  }, []);

  // Fonction pour mettre à jour la propriété current en fonction du chemin
  const updateCurrentState = () => {
    const updatedNavigation = navigation.map((item) => {
      return {
        ...item,
        current: currentPath === item.href,
      };
    });

    return updatedNavigation;
  };

  return (
    <>
      {/* MOBILE NAVBAR */}
      <NavbarMobile
        show={sidebarOpen}
        as={Fragment}
        navigation={updateCurrentState()}
        clients={clients} // Utiliser le state clients ici
        setSidebarOpen={setSidebarOpen}
      />
      {/* NAVBAR WEB */}
      <NavbarWeb
        navigation={updateCurrentState()}
        clients={clients}
        engagements={engagements}
      />
    </>
  );
};

Navbar.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  setSidebarOpen: PropTypes.func.isRequired,
};

export default Navbar;
