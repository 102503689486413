import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ApplicationModal from "../modals/applicationmodal";
import SuccessAlert from "../alerts/successalert";

import { ArrowsExpandIcon } from "@heroicons/react/solid";

import {
  removeApplicationFromEngagement,
  getApplicationsByIds,
} from "../../services/engagementServices";
import { useParams } from "react-router-dom";

export default function applicationstable({
  applicationsIds = [],
  onApplicationUpdated,
}) {
  const [open, setOpen] = useState(false);
  const engagementId = useParams().engagementId;
  const [applicationsData, setApplicationsData] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Get applications data loop through applicationsIds
  useEffect(() => {
    const fetchData = async () => {
      // Ne pas enlever cette condition sinon ça va boucler à l'infini
      if (applicationsIds.length === 0) return;
      try {
        const result = await getApplicationsByIds(applicationsIds);
        setApplicationsData(result);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, [applicationsIds]);

  function handleAddApplication() {
    setOpen(true);
  }

  // Fonction pour supprimer une application
  const handleDeleteApplication = async (id, screen_id) => {
    await removeApplicationFromEngagement(engagementId, id, screen_id);
    const newApplicationsData = applicationsData.filter(
      (application) => application.id !== id,
    );
    setApplicationsData(newApplicationsData);
    setIsDeleted(true);
    onApplicationUpdated();
  };

  // Fonction pour fermer l'alerte de succès
  const handleDismiss = () => {
    setIsDeleted(false);
  };

  // Fonction pour mettre à jour une application
  const updateApplication = () => {
    console.log("update application");
  };

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      {isDeleted && (
        <SuccessAlert
          onDismiss={handleDismiss}
          text="Application supprimée avec succès !"
        />
      )}
      {/* Modal de création d'application */}
      <ApplicationModal
        open={open}
        setOpen={setOpen}
        onApplicationUpdated={onApplicationUpdated}
      />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-white">Applications</h1>
          <p className="mt-2 text-sm text-gray-400">
            Listez les applications utilisées dans le cadre de votre engagement.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={handleAddApplication}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Ajouter une application
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm ring-1 ring-black ring-opacity-5">
              <table
                className="min-w-full border-separate rounded-lg overflow-hidden bg-white ring-1 ring-black ring-opacity-5"
                style={{ borderSpacing: 0 }}
              >
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Nom
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Métier
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      BDD
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      OS
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Hébergement
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Connexion
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Criticité
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    ></th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    ></th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {applicationsData.map((application, index) => (
                    <tr key={index} id={application.id}>
                      <td
                        className={classNames(
                          index !== application.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 text-sm font-medium text-gray-900 pl-4",
                        )}
                      >
                        {application.name}
                      </td>
                      <td
                        className={classNames(
                          index !== application.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 text-sm font-medium text-gray-900 pl-4",
                        )}
                      >
                        {application.type}
                      </td>
                      <td
                        className={classNames(
                          index !== application.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 text-sm font-medium text-gray-900 pl-4",
                        )}
                      >
                        {application.business_process}
                      </td>
                      <td
                        className={classNames(
                          index !== application.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 text-sm font-medium text-gray-900 pl-4",
                        )}
                      >
                        {application.db}
                      </td>
                      <td
                        className={classNames(
                          index !== application.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 text-sm font-medium text-gray-900 pl-4",
                        )}
                      >
                        {application.os}
                      </td>
                      <td
                        className={classNames(
                          index !== application.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 text-sm font-medium text-gray-900 pl-4",
                        )}
                      >
                        {application.hosting}
                      </td>
                      <td
                        className={classNames(
                          index !== application.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 text-sm font-medium text-gray-900 pl-4",
                        )}
                      >
                        {application.connexion_mode}
                      </td>
                      <td
                        className={classNames(
                          index !== application.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 text-sm font-medium text-gray-900 pl-4",
                        )}
                      >
                        {application.criticity}
                      </td>
                      <td
                        className={classNames(
                          index !== application.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap text-sm font-medium text-gray-900",
                        )}
                      >
                        <button
                          onClick={() =>
                            handleDeleteApplication(
                              application.id,
                              application.screen_id,
                            )
                          }
                          className="bg-black/10 hover:bg-red-600 text-white font-bold py-2 px-4 border border-gray-400 rounded"
                        >
                          X
                        </button>
                      </td>
                      <td
                        className={classNames(
                          index !== application.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap text-sm font-medium text-gray-900",
                        )}
                      >
                        <button onClick={updateApplication}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              fillRule="evenodd"
                              d="M15.75 2.25H21a.75.75 0 0 1 .75.75v5.25a.75.75 0 0 1-1.5 0V4.81L8.03 17.03a.75.75 0 0 1-1.06-1.06L19.19 3.75h-3.44a.75.75 0 0 1 0-1.5Zm-10.5 4.5a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h10.5a1.5 1.5 0 0 0 1.5-1.5V10.5a.75.75 0 0 1 1.5 0v8.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V8.25a3 3 0 0 1 3-3h8.25a.75.75 0 0 1 0 1.5H5.25Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Bouton save */}
      <div className="fixed bottom-10 right-20">
        <button className="block w-32 rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          Sauvegarder
        </button>
      </div>
    </div>
  );
}

applicationstable.defaultProps = {
  applicationsData: [],
  onApplicationUpdated: () => {},
};
