import React from "react";
import propTypes from "prop-types";

export default function engagementHeader({ data }) {
  return (
    <div className="w-full h-36 pl-6 flex flex-col items-start justify-center bg-gray-900">
      {/* Nom de l'engagement */}
      <div>
        <h1 className="text-3xl font-bold text-white">
          {data.client.nom} / {data.type}
        </h1>
      </div>
      {/* LOWER LINE */}
      <div className="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
        {/* DESCRIPTION */}
        <p className="truncate">{data.description}</p>

        {/* POINT */}
        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-gray-300">
          <circle cx={1} cy={1} r={1} />
        </svg>
        {/* LAST MODIFICATION (DATE + TIME) */}
        <p className="whitespace-nowrap">
          Modifié le {data.last_modified.toDate().toLocaleString()}
        </p>
        {/* POINT */}
        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 flex-none fill-gray-300">
          <circle cx={1} cy={1} r={1} />
        </svg>
        {/* CREATOR NAME  */}
        <p className="whitespace-nowrap">
          Créé par {data.creator.name + " " + data.creator.firstname}
        </p>
      </div>
    </div>
  );
}

engagementHeader.defaultProps = {
  data: propTypes.object.isRequired,
};
