import React from "react";
import { BriefcaseIcon } from "@heroicons/react/outline";

const teamcard = ({ team }) => {
  // Get creation date
  const date = team.createdAt?.seconds
    ? new Date(team.createdAt.seconds * 1000)
    : new Date();

  // Format the date to 'dd/mm/YYYY'
  const formattedDate = date.toLocaleDateString("fr-FR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return (
    <>
      <a
        key={team.id}
        className="xl:w-1/3 md:w-1/2 p-4"
        href={"/team/" + team.id}
      >
        {/* Ici on utilise l'URL du client ou un lien par défaut si aucune URL n'est fournie */}
        <div className="border border-gray-700 border-opacity-75 p-6 rounded-lg hover:bg-gray-700 transition duration-300 ease-in-out">
          <div className="w-full flex justify-between items-start">
            <div className="w-10 h-10 inline-flex items-center justify-center rounded-full bg-gray-800 text-indigo-400 mb-4">
              <BriefcaseIcon className="w-6 h-6" />
            </div>
            <div className="flex -space-x-1 overflow-hidden p-1">
              {team.members
                ? team.members.map((member, index) => (
                    <img
                      key={index}
                      className="inline-block h-10 w-10 rounded-full ring-2 ring-gray-700"
                      src={member.profilePicture}
                      alt=""
                    />
                  ))
                : ""}
            </div>
          </div>
          <h2 className="text-lg text-white font-medium title-font mb-2">
            {team.teamName}
          </h2>
          <p className="leading-relaxed text-base mb-2 text-gray-300">
            {team.description}
          </p>
          <div className="w-full flex justify-between items-start">
            <p className="leading-relaxed text-base text-gray-400">
              {team.members.length} membres
            </p>
            <p className="leading-relaxed text-base text-gray-400">
              Créée le {formattedDate}
            </p>
          </div>
        </div>
      </a>
    </>
  );
};

export default teamcard;
