import { React, Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { format, set } from "date-fns";
import PropTypes from "prop-types";

import { Treadmill } from "../loading/treadmill";

import { updateFilesListByInputId } from "../../services/engagementServices";
import SuccessAlert from "../alerts/successalert";

export default function DocumentEditModal({
  open,
  setOpen,
  setLoading,
  data,
  input_uid,
  updateData,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    name: data.file.name || "",
    contact: data.file.contact || "",
    receive_by: data.file.receive_by || "",
    comment: data.file.comment || "",
    added_date: data.file.added_date
      ? format(data.file.added_date.toDate(), "yyyy-MM-dd'T'HH:mm:ss.SSS")
      : "",
  });

  useEffect(() => {
    setFormData({
      name: data.file.name || "",
      contact: data.file.contact || "",
      receive_by: data.file.receive_by || "",
      comment: data.file.comment || "",
      added_date: data.file.added_date
        ? format(data.file.added_date.toDate(), "yyyy-MM-dd'T'HH:mm:ss.SSS")
        : "",
    });
  }, [data.file]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await updateFilesListByInputId(input_uid, formData, data.index);
      updateData();
      setOpen(false);
      setLoading(true);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
    setOpen(false);
    setLoading(true);
    setIsSubmitting(false);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-1/2 sm:p-6">
                <h1 className="text-2xl font-bold text-white">
                  Gestion du document
                </h1>
                <div className="flex gap-4 items-start justify-center h-full">
                  <div className="w-1/2 h-full flex-1">
                    <div className="sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-white"
                      >
                        Nom du document
                      </label>
                      <div className="mt-1">
                        <div className="max-w-lg flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <div className="sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="contact"
                          className="block text-sm font-medium text-white"
                        >
                          Interlocuteur
                        </label>
                        <div className="mt-1">
                          <div className="max-w-lg flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="contact"
                              id="contact"
                              autoComplete="contact"
                              value={formData.contact}
                              onChange={handleChange}
                              className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="receive_by"
                          className="block text-sm font-medium text-white"
                        >
                          Reçu par
                        </label>
                        <div className="mt-1">
                          <div className="max-w-lg flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="receive_by"
                              id="receive_by"
                              autoComplete="receive_by"
                              value={formData.receive_by}
                              onChange={handleChange}
                              className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="comment"
                        className="block text-sm font-medium text-white"
                      >
                        Commentaire
                      </label>
                      <div className="mt-1">
                        <div className="max-w-lg flex rounded-md shadow-sm">
                          <textarea
                            name="comment"
                            id="comment"
                            autoComplete="comment"
                            value={formData.comment}
                            rows="5"
                            onChange={handleChange}
                            className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2 flex-1 flex flex-col justify-between">
                    <div className="sm:border-gray-200 sm:pt-5 w-full">
                      <label
                        htmlFor="added_date"
                        className="block text-sm font-medium text-white"
                      >
                        Date de réception
                      </label>
                      <div className="mt-1">
                        <div className="max-w-lg flex rounded-md shadow-sm">
                          <input
                            type="datetime-local"
                            name="added_date"
                            id="added_date"
                            autoComplete="added_date"
                            value={formData.added_date}
                            onChange={handleChange}
                            className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 justify-end">
                  <button
                    type="button"
                    className="rounded-md bg-white/10 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white/20"
                    onClick={() => setOpen(false)}
                  >
                    Annuler
                  </button>
                  {isSubmitting ? (
                    <div
                      type="button"
                      className="flex justify-center align-center w-28 rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    >
                      <Treadmill color="black" size="30" speed="0.5" />
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      onClick={handleSubmit}
                    >
                      Enregistrer
                    </button>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

// Prop validation
DocumentEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
  data: PropTypes.object,
  input_uid: PropTypes.string,
  updateData: PropTypes.func,
};
