import { React, useEffect, useState } from "react";
import {
  Document,
  Paragraph,
  TextRun,
  HeadingLevel,
  AlignmentType,
  Table,
  TableRow,
  TableCell,
  WidthType,
  TableOfContents,
} from "docx";

import {
  getFollowUpByEngagementId,
  getInputsByIds,
} from "./engagementServices";

export default async function createReport(engagementId, engagementData) {
  let applications = [];

  // Get a copy that does not reference source of all the screens from the engagementData.screens list except the first 3 ones
  const applicationScreens = structuredClone(engagementData.screens.slice(3));

  // Fonction récursive pour récupérer les inputs de chaque écran et ses enfants
  async function processScreenAndChildren(screen) {
    // Si l'écran a des inputs, récupère-les
    if (screen.inputs && screen.inputs.length > 0) {
      try {
        const inputs = await getInputsByIds(screen.inputs);
        screen.inputs = inputs; // Mets à jour les inputs de l'écran
      } catch (error) {
        console.error(
          `Error getting inputs data for screen ${screen.name}:`,
          error,
        );
      }
    }

    // Si l'écran a des enfants, appelle la fonction récursive sur chaque enfant
    if (screen.hasChildren && screen.children && screen.children.length > 0) {
      await Promise.all(
        screen.children.map(async (childScreen) => {
          await processScreenAndChildren(childScreen); // Appelle la fonction pour chaque enfant
        }),
      );
    }
  }

  // For each screen, get the inputs data from the database
  try {
    // Utiliser Promise.all pour attendre que tous les inputs soient récupérés, y compris ceux des enfants
    await Promise.all(
      applicationScreens.map(async (screen) => {
        await processScreenAndChildren(screen); // Appelle la fonction récursive sur chaque écran parent
      }),
    );
  } catch (error) {
    console.error("Error processing screens:", error);
  }

  // GET ENGAGEMENT DATA
  try {
    applications = await getFollowUpByEngagementId(engagementId);
    // console.log("applications: ", applications);
  } catch (error) {
    console.log(engagementId);
    console.error("Erreur lors de la récupération des applications :", error);
  }

  // Fonction pour retirer les balises Markdown et retourner du texte brut
  function markdownToPlainText(markdownContent) {
    return markdownContent
      .replace(/\*\*(.*?)\*\*/g, "$1") // Enlever le texte en gras
      .replace(/_(.*?)_/g, "$1") // Enlever le texte en italique
      .replace(/-(.*?)/g, "$1") // Enlever les puces des listes
      .replace(/\n/g, " "); // Remplacer les retours à la ligne par un espace
  }

  // CREATE DOCUMENT
  const doc = new Document({
    features: {
      updateFields: true,
    },
    sections: [
      // Cover page
      {
        properties: {},
        children: coverPageParagraphs(),
      },
      // Table of contents
      {
        children: [
          new TableOfContents("Table des matières", {
            hyperlink: true,
            headingStyleRange: "1-5",
          }),
        ],
      },
      // Controls summary tables
      {
        properties: {},
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Tableau de synthèse des contrôles",
              }),
            ],
            heading: HeadingLevel.HEADING_1,
          }),
          ...controlsSummaryTable(applications),
        ],
      },
      // Applications table
      {
        properties: {},
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Tableau des applications auditées",
              }),
            ],
            heading: HeadingLevel.HEADING_1,
          }),
          ...applicationsTable(applications),
        ],
      },
      // Applications inputs data
      {
        properties: {},
        children: applicationsInputsData(applicationScreens),
      },
    ],
  });

  // COVER PAGE
  function coverPageParagraphs() {
    return [
      new Paragraph({
        children: [
          new TextRun({
            text: "Rapport de mission",
          }),
        ],
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.CENTER,
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: "Audit du système d'information",
          }),
        ],
        heading: HeadingLevel.TITLE,
        alignment: AlignmentType.CENTER,
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: engagementData.client.nom,
          }),
        ],
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.CENTER,
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: "Périmètre comptabilité et ventes",
            italics: true,
          }),
        ],
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.CENTER,
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: "Exercice 2023-2024",
            italics: true,
          }),
        ],
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.CENTER,
      }),
    ];
  }

  // CONTROLS SUMMARY TABLE
  function controlsSummaryTable(applications) {
    return applications.flatMap((application, index) => [
      // Line break
      new Paragraph({
        children: [
          new TextRun({
            text: "",
            break: 2,
          }),
        ],
      }),

      // Application name
      new Paragraph({
        children: [
          new TextRun({
            text: application.applicationData.name,
          }),
        ],
        heading: HeadingLevel.HEADING_2,
      }),

      // Line break
      new Paragraph({
        children: [
          new TextRun({
            text: "",
            break: 1,
          }),
        ],
      }),

      // Table
      new Table({
        columnWidths: [4000, 2000, 2000],
        rows: [
          // Header
          new TableRow({
            children: [
              new TableCell({
                width: {
                  size: 4000,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [new TextRun({ text: "Nom", bold: true })],
                  }),
                ],
              }),
              new TableCell({
                width: {
                  size: 2000,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({ text: "Effectivité", bold: true }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                width: {
                  size: 2000,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [new TextRun({ text: "Criticité", bold: true })],
                  }),
                ],
              }),
            ],
          }),
          // Loop through application.screens and create a new row for each screen
          ...application.screens.map(
            (screen) =>
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [new TextRun(screen.name)],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun(
                            screen.inputs[0]?.value &&
                            screen.inputs[0]?.value.length > 0
                              ? screen.inputs[0].value == "OUI"
                                ? "Effectif"
                                : "Non effectif"
                              : "NA",
                          ),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun(
                            screen.inputs[1]?.value &&
                            screen.inputs[1]?.value.length > 0
                              ? screen.inputs[1].value.toString()
                              : "NA",
                          ),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
          ),
        ],
      }),
    ]);
  }

  // APPLICATIONS TABLE
  function applicationsTable(applications) {
    return [
      // Line break
      new Paragraph({
        children: [
          new TextRun({
            text: "",
            break: 2,
          }),
        ],
      }),
      // Table
      new Table({
        columnWidths: [4000, 2000, 2000],
        rows: [
          // Header
          new TableRow({
            children: [
              new TableCell({
                width: {
                  size: 4000,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [new TextRun({ text: "Nom", bold: true })],
                  }),
                ],
              }),
              new TableCell({
                width: {
                  size: 2000,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [new TextRun({ text: "Type", bold: true })],
                  }),
                ],
              }),
              new TableCell({
                width: {
                  size: 2000,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [new TextRun({ text: "Métier", bold: true })],
                  }),
                ],
              }),
              new TableCell({
                width: {
                  size: 2000,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [new TextRun({ text: "BDD", bold: true })],
                  }),
                ],
              }),
              new TableCell({
                width: {
                  size: 2000,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [new TextRun({ text: "OS", bold: true })],
                  }),
                ],
              }),
              new TableCell({
                width: {
                  size: 2000,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({ text: "Hébergement", bold: true }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                width: {
                  size: 2000,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [new TextRun({ text: "Connexion", bold: true })],
                  }),
                ],
              }),
              new TableCell({
                width: {
                  size: 2000,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [new TextRun({ text: "Criticité", bold: true })],
                  }),
                ],
              }),
            ],
          }),
          // Loop through applications and create a new row for each application
          ...applications.map(
            (application) =>
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun(application.applicationData.name),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun(application.applicationData.type),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun(
                            application.applicationData.business_process,
                          ),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [new TextRun(application.applicationData.db)],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [new TextRun(application.applicationData.os)],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun(application.applicationData.hosting),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun(
                            application.applicationData.connexion_mode,
                          ),
                        ],
                      }),
                    ],
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun(application.applicationData.criticity),
                        ],
                      }),
                    ],
                  }),
                ],
              }),
          ),
        ],
      }),
    ];
  }

  function applicationsInputsData(applicationScreens) {
    // Fonction récursive pour créer des sections avec des niveaux de profondeur
    function createSection(screen, depth) {
      const headingLevel = HeadingLevel[`HEADING_${depth}`]; // Calcule le niveau de heading basé sur la profondeur

      // Crée un paragraphe pour l'écran actuel avec le bon niveau de heading
      const section = [
        new Paragraph({
          children: [
            new TextRun({
              text: screen.name,
              bold: true,
              break: 1,
            }),
          ],
          heading: headingLevel, // Heading en fonction de la profondeur
          pageBreakBefore: depth === 1, // Si profondeur = 1, insère un saut de page avant
        }),

        // Ajoute un paragraphe pour la description de l'écran (si elle existe)
        ...(screen.description &&
        screen.description != "Description de l'engagement"
          ? [
              new Paragraph({
                children: [
                  new TextRun({
                    text: markdownToPlainText(screen.description),
                  }),
                ],
              }),
            ]
          : []), // Si pas de description, pas de paragraphe ajouté
      ];

      // Ajoute un paragraphe pour afficher le label de chaque input
      if (screen.inputs && screen.inputs.length > 0) {
        // Line break
        section.push(
          new Paragraph({
            children: [
              new TextRun({
                text: "",
                break: 1,
              }),
            ],
          }),
        );

        // Ajoute un paragraphe pour chaque input
        screen.inputs.forEach((input) => {
          section.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: `${input.label}`,
                  break: 1,
                }),
                new TextRun({
                  text: `${input?.value ? input.value : "Non renseigné"}`,
                  italics: true,
                  break: 1,
                }),
              ],
            }),
          );
        });
      }

      // Si l'écran a des enfants, on les affiche récursivement avec une profondeur accrue
      if (screen.hasChildren && screen.children.length > 0) {
        screen.children.forEach((childScreen) => {
          section.push(...createSection(childScreen, depth + 1)); // Augmente la profondeur pour les enfants
        });
      }

      return section;
    }

    // Crée des sections pour tous les écrans au niveau racine
    return applicationScreens.flatMap((screen) => createSection(screen, 1)); // Profondeur de base est 0 (HEADING_1)
  }

  return doc;
}
