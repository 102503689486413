import { db } from "../config/firebase";
import {
  doc,
  getDoc,
  addDoc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  deleteDoc,
  limit,
} from "firebase/firestore";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const entreprisesConnuess = [
  {
    adresse_postale: "1600 Amphitheatre Parkway, Mountain View, CA 94043",
    description:
      "Multinational technology company specializing in Internet-related services and products.",
    email: "contact@google.com",
    nom: "Google",
    num_tel: "1-650-253-0000",
    secteur_activite: "Technologie",
  },
  {
    adresse_postale: "One Apple Park Way, Cupertino, CA 95014",
    description:
      "Technology company that designs, manufactures, and markets smartphones, personal computers, tablets, wearables, and accessories.",
    email: "contact@apple.com",
    nom: "Apple Inc.",
    num_tel: "1-408-996–1010",
    secteur_activite: "Technologie",
  },
  {
    adresse_postale: "1600 Amphitheatre Parkway, Mountain View, CA 94043",
    description:
      "Multinational technology company specializing in Internet-related services and products.",
    email: "contact@google.com",
    nom: "Google",
    num_tel: "1-650-253-0000",
    secteur_activite: "Technologie",
  },
  {
    adresse_postale: "One Apple Park Way, Cupertino, CA 95014",
    description:
      "Technology company that designs, manufactures, and markets smartphones, personal computers, tablets, wearables, and accessories.",
    email: "contact@apple.com",
    nom: "Apple Inc.",
    num_tel: "1-408-996–1010",
    secteur_activite: "Technologie",
  },
  // Ajout de 8 entreprises françaises...
  {
    adresse_postale: "16, boulevard Malesherbes, 75008 Paris, France",
    description:
      "Un des principaux acteurs mondiaux de l'assurance et de la gestion d'actifs.",
    email: "contact@axa.com",
    nom: "AXA",
    num_tel: "01 40 75 57 00",
    secteur_activite: "Assurance",
  },
  {
    adresse_postale:
      "1, cours Ferdinand de Lesseps, 92500 Rueil-Malmaison, France",
    description:
      "Société multinationale de services en énergie et communications.",
    email: "contact@bouyges.com",
    nom: "Bouygues",
    num_tel: "01 44 20 10 00",
    secteur_activite: "Construction, Média, Télécommunications",
  },
  {
    adresse_postale:
      "2, place Jean Millier, La Défense, 92400 Courbevoie, France",
    description: "Une des premières compagnies pétrolières mondiales.",
    email: "contact@total.com",
    nom: "TotalEnergies",
    num_tel: "01 47 44 45 46",
    secteur_activite: "Energie",
  },
  {
    adresse_postale: "7, Place du Chancelier Adenauer, 75016 Paris, France",
    description: "Leader mondial des produits laitiers frais.",
    email: "contact@danone.com",
    nom: "Danone",
    num_tel: "01 44 35 20 20",
    secteur_activite: "Agroalimentaire",
  },
  {
    adresse_postale: "17, boulevard Haussmann, 75009 Paris, France",
    description: "Groupe bancaire français leader en Europe.",
    email: "contact@societegenerale.com",
    nom: "Société Générale",
    num_tel: "01 42 14 20 00",
    secteur_activite: "Banque",
  },
  {
    adresse_postale: "43, quai de Grenelle, 75015 Paris, France",
    description: "Un des principaux fournisseurs mondiaux d'électricité.",
    email: "contact@edf.fr",
    nom: "Électricité de France (EDF)",
    num_tel: "01 40 42 22 22",
    secteur_activite: "Energie",
  },
  {
    adresse_postale: "31-32, quai de Dion Bouton, 92800 Puteaux, France",
    description:
      "Entreprise spécialisée dans la vente de produits cosmétiques.",
    email: "contact@loreal.com",
    nom: "L'Oréal",
    num_tel: "01 47 56 70 00",
    secteur_activite: "Cosmétique",
  },
  {
    adresse_postale: "33, rue La Fayette, 75009 Paris, France",
    description: "Premier groupe hôtelier en Europe.",
    email: "contact@accor.com",
    nom: "Accor",
    num_tel: "01 45 38 86 00",
    secteur_activite: "Hôtellerie",
  },
  {
    adresse_postale: "12, rue de la Boétie, 75008 Paris, France",
    description:
      "Marque française de luxe spécialisée dans la mode et la maroquinerie de luxe.",
    email: "contact@hermes.com",
    nom: "Hermès",
    num_tel: "01 40 17 49 20",
    secteur_activite: "Luxe",
  },
  // Ajoutez des objets similaires pour 8 autres entreprises connues...
];

async function createTestClients() {
  try {
    const createdDocs = [];
    for (const newClient of entreprisesConnuess) {
      const docRef = await addDoc(collection(db, "clients"), newClient);
      console.log("Document écrit avec l'ID: ", docRef.id);
      createdDocs.push(docRef);
    }
    return createdDocs;
  } catch (error) {
    console.error("Erreur lors de la création des clients:", error);
    throw error;
  }
}

async function createClient(clientDetails) {
  try {
    // Ajouter le nouvel objet client à la collection 'clients'
    const docRef = await addDoc(collection(db, "clients"), clientDetails);
    console.log("Document écrit avec l'ID: ", docRef.id);
    return docRef;
  } catch (error) {
    console.error("Erreur lors de la création du client:", error);
    throw error;
  }
}

async function getAllClients(resultsLimit, archived = false) {
  try {
    // Création d'une requête pour sélectionner la collection 'clients' avec une limite
    const clientsCollectionRef = collection(db, "clients");
    const clientsQuery = query(
      clientsCollectionRef,
      limit(resultsLimit),
      orderBy("nom"),
    );

    // Exécution de la requête pour obtenir les documents
    const querySnapshot = await getDocs(clientsQuery);

    // Créer un tableau pour stocker les données des clients
    let clients = [];

    // Itérer sur chaque document dans la collection 'clients'
    querySnapshot.forEach((doc) => {
      const clientData = doc.data();

      // Si archived est vrai, on récupère tous les clients
      if (archived) {
        clients.push({ id: doc.id, ...clientData });
      }
      // Si archived est faux, on récupère les clients non archivés
      else {
        if (!clientData.archived || clientData.archived === false) {
          clients.push({ id: doc.id, ...clientData });
        }
      }
    });

    return clients;
  } catch (error) {
    console.error("Erreur lors de la récupération des clients:", error);
    throw error;
  }
}

// Appeler la fonction pour créer des clients de test
// createTestClients();

async function deleteAllClients() {
  try {
    // Récupérer une référence à la collection 'clients'
    const clientsCollectionRef = collection(db, "clients");

    // Obtenir tous les documents de la collection 'clients'
    const querySnapshot = await getDocs(clientsCollectionRef);

    // Créer un tableau de promesses de suppression
    const deletePromises = querySnapshot.docs.map((document) =>
      deleteDoc(doc(db, "clients", document.id)),
    );

    // Attendre que toutes les promesses de suppression soient résolues
    await Promise.all(deletePromises);

    console.log(
      "Tous les documents de la collection 'clients' ont été supprimés.",
    );
  } catch (error) {
    console.error("Erreur lors de la suppression des documents:", error);
    throw error;
  }
}

// Detelete client by id

async function deleteClientById(clientId) {
  try {
    // Créer une référence au document du client spécifique par son ID
    const clientDocRef = doc(db, "clients", clientId);

    // Supprimer le document client
    await deleteDoc(clientDocRef);

    console.log("Document supprimé avec succès !");
  } catch (error) {
    console.error("Erreur lors de la suppression du client:", error);
    throw error;
  }
}

// Archive client by id
async function archiveClientById(clientId) {
  try {
    // Créer une référence au document du client spécifique par son ID
    const clientDocRef = doc(db, "clients", clientId);

    // Mettre à jour le document client avec un champ d'archive
    await updateDoc(clientDocRef, { archived: true });

    console.log("Document archivé avec succès !");
  } catch (error) {
    console.error("Erreur lors de l'archivage du client:", error);
    throw error;
  }
}

// Appeler la fonction pour supprimer tous les clients
// deleteAllClients();

async function getClientById(clientId) {
  try {
    // Créer une référence au document du client spécifique par son ID
    const clientDocRef = doc(db, "clients", clientId);

    // Récupérer le document
    const docSnapshot = await getDoc(clientDocRef);

    if (docSnapshot.exists()) {
      // Le document existe, retourner l'ID et les données du client
      return { id: docSnapshot.id, ...docSnapshot.data() };
    } else {
      // Le document n'existe pas, retourner un message ou null
      console.log("Aucun document correspondant à cet ID !");
      return null;
    }
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des informations du client:",
      error,
    );
    throw error;
  }
}

//Update client by id
async function updateClientById(clientId, clientDetails) {
  try {
    // Créer une référence au document du client spécifique par son ID
    const clientDocRef = doc(db, "clients", clientId);

    // Mettre à jour les données du document client
    await updateDoc(clientDocRef, clientDetails);

    console.log("Document mis à jour avec succès !");
  } catch (error) {
    console.error("Erreur lors de la mise à jour du client:", error);
    throw error;
  }
}

// Get favorite clients informations by user Id
async function getFavoriteClientsInfo(userId) {
  try {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error(`User with ID ${userId} does not exist.`);
    }

    const userData = userDoc.data();
    const favoriteClients = userData.favoriteClients || [];

    // Create an array to store the details of favorite clients
    let favoriteClientsInfo = [];

    for (const clientId of favoriteClients) {
      const clientInfo = await getClientById(clientId);
      if (clientInfo) {
        favoriteClientsInfo.push(clientInfo);
      }
    }

    return favoriteClientsInfo;
  } catch (error) {
    console.error("Error getting favorite clients info: ", error);
    throw error;
  }
}

export {
  getAllClients,
  createTestClients,
  getClientById,
  createClient,
  updateClientById,
  getFavoriteClientsInfo,
  deleteClientById,
  archiveClientById,
};
