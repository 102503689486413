import { React, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Engagementbodyinputs from "./engagementbodyinputs";
import { getInputsByIds } from "../../services/engagementServices";
import Applicationstable from "./applicationstable";
import SuiviPAge from "./suivipage";
import ParameterScreen from "./parameterscreen";
import Markdown from "react-markdown";

export default function EngagementBody({
  engagementData,
  screenData,
  applicationsIds,
  onApplicationUpdated,
  updateData,
  onNavigate,
}) {
  const markdownText = `
**Description :** Les comptes ayant des droits d’accès étendus comme les administrateurs ou les super utilisateurs ne sont pas suffisamment restreints ou supervisés.

**Risques :**
* Risque de non-maîtrise des accès internes et externes au SI.
* Risque de détournement de comptes utilisateurs et d'actifs en cas d'actes malveillants ou d'usurpation d'identité.
* Enregistrement d’opérations non autorisées, au-delà des prérogatives de l’utilisateur.

**Conception :**
* Obtenir la procédure de gestion des accès ayant des droits étendus (administrateurs ou super-utilisateurs). La procédure devra couvrir les personnes autorisées à posséder ces droits et la revue périodique de ces droits.
`;

  return (
    <>
      <header className="flex flex-col items-center w-4/5">
        <h1 className="text-gray-400 font-bold text-center">
          {screenData.subtitle}
        </h1>
        <h3 className="text-5xl font-bold text-white mt-2 text-center">
          {screenData.name}
        </h3>
        <Markdown className="mt-12 text-white prose max-w-none">
          {screenData.description}
        </Markdown>
      </header>
      <div className="w-full mt-8 flex flex-col justify-center items-justify">
        {/* Utilisation de l'opérateur ternaire pour afficher les composants en fonction de screenData.id */}
        {screenData.id === "11" && (
          <ParameterScreen engagementData={engagementData} />
        )}
        {screenData.id === "21" && (
          <>
            <Applicationstable
              applicationsIds={applicationsIds}
              onApplicationUpdated={onApplicationUpdated}
            />
            {/* <Engagementbodyinputs data={screenData} /> */}
          </>
        )}
        {screenData.id === "13" && <SuiviPAge onNavigate={onNavigate} />}
        {screenData.id !== "11" &&
          screenData.id !== "21" &&
          screenData.id !== "13" && (
            <Engagementbodyinputs data={screenData} updateData={updateData} />
          )}
      </div>
    </>
  );
}

EngagementBody.propTypes = {
  engagementData: PropTypes.object.isRequired,
  screenData: PropTypes.object.isRequired,
  applicationsIds: PropTypes.array,
  onApplicationUpdated: PropTypes.func,
  updateData: PropTypes.func,
  onNavigate: PropTypes.func,
};
