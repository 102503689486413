import React, { useState, useEffect } from "react";

import TeamsCard from "./teamcard";
import { UserGroupIcon } from "@heroicons/react/outline";

import { addTeam, getAllTeams } from "../services/teamServices";

import SectionHeader from "./sectionheader";

import { Treadmill } from "./loading/treadmill";

import PropTypes from "prop-types";

const TeamsSection = ({ search }) => {
  const [teams, setTeams] = useState([]); // State for team
  const [filteredClients, setFilteredClients] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const teamsData = await getAllTeams();
        setTeams(teamsData);
        setFilteredClients(teamsData);
      } catch (error) {
        console.error("Error fetching teams:", error);
        // Handle error
      } finally {
        setLoading(false);
      }
    };

    fetchTeams();
  }, []);

  useEffect(() => {
    if (search != "") {
      setFilteredClients(
        teams.filter((team) =>
          team.teamName.toLowerCase().includes(search.toLowerCase()),
        ),
      );
    } else {
      setFilteredClients(teams);
    }
  }, [search]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Treadmill />
      </div>
    );
  }

  return (
    <section className="text-gray-400 body-font bg-gray-900">
      <div className="container px-5 mx-auto">
        <SectionHeader
          label={"Team"}
          link={""}
          Icon={UserGroupIcon}
          Create="equipe/create"
          Link=""
        />
        <div className="flex flex-wrap m-4">
          {filteredClients.map((team, index) => (
            <TeamsCard key={index} team={team} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamsSection;

TeamsSection.propTypes = {
  setSidebarOpen: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
};
