import React from "react";
import { treadmill } from "ldrs";
import PropTypes from "prop-types";
treadmill.register(); // Default values
export const Treadmill = ({ color = "white", size = "70", speed = "1.25" }) => {
  return (
    <>
      <l-treadmill size={size} speed={speed} color={color}></l-treadmill>
    </>
  );
};

Treadmill.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  speed: PropTypes.string,
};
