import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid";
import PropTypes from "prop-types";
import { Button } from "bootstrap";
/* This example requires Tailwind CSS v2.0+ */

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SideBarItem({ item, onNavigate }) {
  const handleClick = () => {
    if (onNavigate && item.id) {
      onNavigate(item.id);
    } else {
      console.log("No onNavigate function or item.id");
    }
  };

  if (item.hasChildren) {
    return (
      <Disclosure as="div" key={item.name} className="space-y-1">
        {({ open }) => (
          <>
            <Disclosure.Button
              className={classNames(
                item.current
                  ? "bg-gray-800 text-white"
                  : "text-gray-400 hover:text-white hover:bg-gray-800",
                "group flex justify-start gap-x-3 w-full rounded-md p-2 text-sm text-left leading-6 font-semibold",
              )}
            >
              {open ? (
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              ) : (
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              )}
              {item.name}
            </Disclosure.Button>
            <Disclosure.Panel className="space-y-1">
              {item.children.map((subItem) => (
                <div key={subItem.name} className="pl-4">
                  <SideBarItem item={subItem} onNavigate={onNavigate} />
                </div>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  } else {
    return (
      // Button
      <button
        key={item.name}
        onClick={handleClick}
        className={classNames(
          item.current
            ? "bg-gray-800 text-white"
            : "text-gray-400 hover:text-white hover:bg-gray-800",
          "group flex justify-start text-left gap-x-3 w-full rounded-md p-2 text-sm leading-6 font-semibold",
        )}
      >
        {item.name}
      </button>
    );
  }
}

SideBarItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    href: PropTypes.string,
    current: PropTypes.bool,
    hasChildren: PropTypes.bool,
    children: PropTypes.array,
    id: PropTypes.string,
  }),
  onNavigate: PropTypes.func.isRequired,
};
