import { React, useState } from "react";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import { CheckIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { ChevronDownIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectDocuments({
  documents,
  selectedDocuments,
  setSelectedDocuments,
}) {
  const [isOpen, setIsOpen] = useState(false);

  function isSelected(value) {
    return selectedDocuments.find((el) => el.url === value.url) ? true : false;
  }

  function handleSelect(value) {
    if (!isSelected(value)) {
      setSelectedDocuments([...selectedDocuments, value]);
    } else {
      handleDeselect(value);
    }
    setIsOpen(true);
  }

  function handleDeselect(value) {
    const selectedDocumentsUpdated = selectedDocuments.filter(
      (doc) => doc.url !== value.url,
    );
    setSelectedDocuments(selectedDocumentsUpdated);
    setIsOpen(true);
  }

  const getNameById = (doc) => {
    const document = documents.find((document) => document.url === doc.url);
    return document ? document.name : "null";
  };

  return (
    <Listbox
      as="div"
      className="space-y-1 sm:col-span-2"
      value={selectedDocuments}
      onChange={(value) => handleSelect(value)}
      open={isOpen}
    >
      {() => (
        <>
          <div className="relative mt-2">
            <span className="inline-block w-full rounded-md shadow-sm">
              <Listbox.Button
                className="cursor-default relative w-full rounded-md border border-gray-700 bg-gray-800 text-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                onClick={() => setIsOpen(!isOpen)}
                open={isOpen}
              >
                <span className="block truncate">
                  {selectedDocuments.length < 1
                    ? "Select documents"
                    : `Selected documents (${selectedDocuments.length})`}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Listbox.Button>
            </span>

            <Transition
              unmount={false}
              show={isOpen}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute mt-1 w-full rounded-md bg-gray-800 shadow-lg"
            >
              <Listbox.Options
                static
                className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
              >
                {documents.map((document) => {
                  const selected = isSelected(document);
                  return (
                    <Listbox.Option key={document.url} value={document}>
                      {({ active }) => (
                        <div
                          className={`${
                            active
                              ? "text-white bg-blue-600 flex items-center"
                              : "text-white flex items-center"
                          } cursor-default select-none relative py-2 pl-8 pr-4 flex items-center`}
                        >
                          <span
                            className={`${
                              selected
                                ? "font-semibold ml-3"
                                : "font-normal ml-3"
                            } block truncate`}
                          >
                            {document.name}
                          </span>
                          {selected && (
                            <span
                              className={`${
                                active ? "text-white" : "text-blue-600"
                              } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                            >
                              <svg
                                className="h-5 w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          )}
                        </div>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
            <div className="pt-1 text-sm text-gray-400">
              {selectedDocuments.length > 0 && (
                <>
                  <p className="font-bold">Selected documents:</p>
                  <ul>
                    {selectedDocuments.length > 0 && (
                      <>
                        {selectedDocuments.map((documentSelected, index) => (
                          <li key={index} className="list-disc list-inside ...">
                            {getNameById(documentSelected)}
                          </li>
                        ))}
                      </>
                    )}
                  </ul>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </Listbox>
  );
}

// Props
SelectDocuments.propTypes = {
  documents: PropTypes.array.isRequired,
  selectedDocuments: PropTypes.array.isRequired,
  setSelectedDocuments: PropTypes.func.isRequired,
};
