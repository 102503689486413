import { React, useState, useEffect } from "react";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { getFollowUpByEngagementId } from "../../services/engagementServices";
import { useParams } from "react-router-dom";
import { Treadmill } from "../loading/treadmill";
import PropTypes from "prop-types";

export default function SuiviPage({ onNavigate }) {
  const [applications, setApplications] = useState([]);
  const { engagementId } = useParams();
  const [loading, setLoading] = useState(true);

  console.log(engagementId);

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await getFollowUpByEngagementId(engagementId);
        setApplications(result);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    }
    fetchData();
  }, [engagementId]);

  const calculateCompletionPercentage = (application) => {
    let totalFields = 0;
    let completedFields = 0;

    application.screens.forEach((screen) => {
      screen.inputs.forEach((input) => {
        totalFields += 1;
        if (input.value && input.value.length > 0) {
          completedFields += 1;
        }
      });
    });

    return ((completedFields / totalFields) * 100).toFixed(2);
  };

  const criticityStateStyle = (value) => {
    if (value.length == 0 || value == []) {
      return "";
    } else {
      //switch on value
      switch (value[0]) {
        case "Elevé":
          return "text-red-500";
        case "Modéré":
          return "text-orange-500";
        case "Faible":
          return "text-green-500";
        default:
          return "";
      }
    }
  };

  const effectivityStateStyle = (value) => {
    switch (value[0]) {
      case "OUI":
        return "text-green-500";
      case "NON":
        return "text-red-500";
      default:
        return "text-black";
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-12">
        <Treadmill />
      </div>
    );
  }

  return (
    <>
      {applications.map((application, indexApp) => (
        <div
          key={indexApp}
          className="m-4 mt-6 border-solid border-2 border-gray-700 rounded-lg shadow-xl"
        >
          <div className="bg-gray-900">
            <div className="">
              <div className="bg-gray-900">
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-white">
                        {application.applicationData.name}
                      </h1>
                      <p className="mt-2 text-sm text-gray-300">
                        {application.applicationData.description}
                      </p>
                    </div>
                    <div className="bg-gray-900 px-4 py-6 sm:px-6 lg:px-8">
                      <p className="text-sm font-medium leading-6 text-gray-400">
                        Complétion
                      </p>
                      <p className="mt-2 flex items-baseline gap-x-2">
                        <span className="text-4xl font-semibold tracking-tight text-white">
                          {calculateCompletionPercentage(application)}%
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="mt-2 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-700">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0"
                              >
                                Nom
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Effectivité
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-white"
                              >
                                Criticité
                              </th>
                              <th scope="col" className="relative px-3 py-3.5">
                                <span className="sr-only"></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-800">
                            {application.screens.map((screen) => (
                              <tr key={screen.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                                  {screen.name}
                                </td>
                                <td
                                  className={`whitespace-nowrap px-3 py-4 text-sm ${
                                    screen.inputs[0]?.value &&
                                    effectivityStateStyle(
                                      screen.inputs[0]?.value,
                                    )
                                  }`}
                                >
                                  {screen.inputs[0]?.value &&
                                  screen.inputs[0]?.value.length > 0
                                    ? screen.inputs[0].value == "OUI"
                                      ? "Effectif"
                                      : "Non effectif"
                                    : "NA"}
                                </td>
                                <td
                                  className={`whitespace-nowrap px-3 py-4 text-sm ${
                                    screen.inputs[1]?.value &&
                                    criticityStateStyle(screen.inputs[1]?.value)
                                  }`}
                                >
                                  {screen.inputs[1]?.value &&
                                  screen.inputs[1]?.value.length > 0
                                    ? screen.inputs[1].value
                                    : "NA"}
                                </td>
                                <td className="pr-4 py-4 text-right text-sm font-medium whitespace-nowrap">
                                  <button
                                    onClick={() => onNavigate(screen.id)}
                                    className="text-indigo-600 hover:text-indigo-900"
                                  >
                                    <ArrowRightIcon className="h-5 w-5" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

SuiviPage.propTypes = {
  onNavigate: PropTypes.func,
};
