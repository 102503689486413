import React from "react";
import { BrowserRouter, Route, Routes, Switch } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";

import { AuthProvider } from "./contexts/AuthContext";

import {
  Accueil,
  Profile,
  NotFound,
  Connexion,
  Engagement,
  Engagements,
  EngagementCreate,
  Team,
  Teams,
  Clients,
  Client,
  ClientEdit,
  ClientCreate,
} from "./pages";

import EquipeCreate from "./pages/equipecreate";
import ProfileModify from "./pages/profilemodify";

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route exact path="/" element={<Accueil />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/profile/modify" element={<ProfileModify />} />
            <Route
              exact
              path="/engagement/:engagementId"
              element={<Engagement />}
            />
            <Route exact path="/engagements" element={<Engagements />} />
            <Route
              exact
              path="/engagement/create"
              element={<EngagementCreate />}
            />
            <Route exact path="/team/:teamId" element={<Team />} />
            <Route exact path="/equipe/" element={<Teams />} />
            <Route exact path="/equipe/create" element={<EquipeCreate />} />
            <Route exact path="/clients/" element={<Clients />} />
            <Route exact path="/client/:clientId" element={<Client />} />
            <Route
              exact
              path="/clientedit/:clientId"
              element={<ClientEdit />}
            />
            <Route exact path="/client/create" element={<ClientCreate />} />
          </Route>
          <Route path="/connexion" element={<Connexion />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
