import { React, useState } from "react";
import { createClient } from "../../services/clientServices";
import SuccessAlert from "../alerts/successalert";

import { Treadmill } from "../loading/treadmill";
import { useNavigate } from "react-router-dom";

export default function CreateClientForm() {
  const [client, setClient] = useState({
    nom: "",
    email: "",
    num_tel: "",
    adresse_postale: "",
    secteur_activite: "",
    description: "",
  });
  const [isCreated, setIsCreated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClient({ ...client, [name]: value });
  };

  const handleSubmit = async (e) => {
    setIsCreating(true);
    e.preventDefault();
    try {
      await createClient(client);
      setIsCreating(false);
      setIsCreated(true);
      navigate("/clients");
    } catch (error) {
      console.error("Erreur lors de la création du client:", error);
      setIsCreating(false);
    }
  };

  // Fonction pour fermer l'alerte de succès
  const handleDismiss = () => {
    setIsCreated(false);
  };

  return (
    <div className="isolate bg-gray-900 px-6 py-24 sm:py-10 lg:px-8">
      {isCreated && (
        <SuccessAlert
          onDismiss={handleDismiss}
          text="Client créée avec succès !"
        />
      )}
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Création d&apos;un client
        </h2>
        <p className="mt-2 text-lg leading-8 text-gray-400">
          Renseignez ici les informations relatives à l&apos;activité d&apos;un
          client.
        </p>
      </div>
      <div className="flex justify-center mt-10">
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-200 w-3/5 mt-10"
        >
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
              <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                {/* Nom */}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
                  >
                    Nom
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="nom"
                        id="nom"
                        autoComplete="nom"
                        value={client.nom}
                        onChange={handleChange}
                        className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                      />
                    </div>
                  </div>
                </div>

                {/* Description */}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
                  >
                    Description
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <textarea
                      id="description"
                      name="description"
                      rows={3}
                      value={client.description}
                      onChange={handleChange}
                      className="max-w-lg shadow-sm bg-gray-800 text-white block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-700 rounded-md"
                      defaultValue={""}
                    />
                    <p className="mt-2 text-sm text-gray-400">
                      Décrire le client en quelques mots.
                    </p>
                  </div>
                </div>

                {/* Email */}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
                  >
                    Adresse email
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        autoComplete="email"
                        value={client.email}
                        onChange={handleChange}
                        className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                      />
                    </div>
                  </div>
                </div>

                {/* Num tel */}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
                  >
                    Numéro de téléphone
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg flex rounded-md shadow-sm">
                      <input
                        type="tel"
                        name="num_tel"
                        id="num_tel"
                        autoComplete="tel"
                        value={client.num_tel}
                        onChange={handleChange}
                        className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                      />
                    </div>
                  </div>
                </div>

                {/* Adresse postale */}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
                  >
                    Adresse postale
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="adresse_postale"
                        id="adresse_postale"
                        autoComplete="street-address"
                        value={client.adresse_postale}
                        onChange={handleChange}
                        className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                      />
                    </div>
                  </div>
                </div>

                {/* Secteur d'activité */}
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="username"
                    className="block text-sm font-medium text-white sm:mt-px sm:pt-2"
                  >
                    Secteur d&apos;activité
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg flex rounded-md shadow-sm">
                      <input
                        type="text"
                        name="secteur_activite"
                        id="secteur_activite"
                        autoComplete=""
                        value={client.secteur_activite}
                        onChange={handleChange}
                        className="flex-1 block w-full bg-gray-800 text-white focus:ring-gray-300 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-700"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            {!isCreating ? (
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Créer
                </button>
              </div>
            ) : (
              <div className="flex justify-end">
                <div className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <Treadmill color="black" size="30" speed="0.5" />
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
